<template>
  <main class="home-page" id="top">
    <!-- 导航菜单 -->
    <div class="sticky-sidebar">
      <div
        class="sidebar"
        @mousemove="handleMouseMove"
        @mouseleave="handleMouseLeave1"
      >
        <div class="glow-circle" v-if="showGlow" :style="glowStyle"></div>
        <div class="logo">
          <div class="left">
            <img src="../assets/home/LOGO.png" alt="" />
          </div>
        </div>
        <nav class="menu">
          <ul>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === '首页' }]"
                @click="navigateToPage('/')"
              >
                <div class="menu-item-content">
                  <home-icon />
                  <span>首页</span>
                </div>
              </button>
            </li>
            <el-divider />
            <h4>德娃AI社区</h4>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === '视频广场' }]"
                @click="navigateToPage('/square')"
              >
                <div class="menu-item-content">
                  <lightbulb-icon />
                  <span>视频广场</span>
                </div>
              </button>
            </li>
            <el-divider />
            <h4>AI视频</h4>
            <li
              class="menu-item-wrapper"
              @click="showVideoOptionsDialog = true"
            >
              <button
                :class="['menu-item', { active: activeItem === '图转视频' }]"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>图转视频</span>
                </div>
                <chevron-right-icon />
              </button>
              <!-- <div v-if="hoveredItem === '图转视频'" class="submenu" @mouseenter="hoveredItem = '图转视频'"
                @mouseleave="hoveredItem = null">
                <div class="submenu-item" @click="openwork1">
                  runway类型
                </div>
                <div class="submenu-item" @click="openwork2">
                  luma类型
                </div>
                <div class="submenu-item" @click="openwork3">
                  kling类型
                </div>
                <div class="submenu-item" @click="openwork1">
                  pik类型
                </div>
              </div> -->
            </li>
            <li
              class="menu-item-wrapper"
              @click="showVideoOptionsDialog1 = true"
            >
              <button
                :class="['menu-item', { active: activeItem === '文转视频' }]"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>文转视频</span>
                </div>
                <chevron-right-icon />
              </button>
              <!-- <div v-if="hoveredItem === '文转视频'" class="submenu" @mouseenter="hoveredItem = '文转视频'"
                @mouseleave="hoveredItem = null">
                <div class="submenu-item" @click="openwork5">
                  runway类型
                </div>
                <div class="submenu-item" @click="openwork5">
                  luma类型
                </div>
                <div class="submenu-item" @click="openwork5">
                  kling类型
                </div>
                <div class="submenu-item" @click="openwork5">
                  pik类型
                </div>
              </div> -->
            </li>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === '视频转视频' }]"
                @click="openwork9"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>视频转视频</span>
                </div>
              </button>
            </li>
            <el-divider />
            <h4>AI视频玩法</h4>
            <li class="menu-item-wrapper">
              <button
                :class="[
                  'menu-item',
                  { active: activeItem === 'AI老照片视频' },
                ]"
                @click="openwork6"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>AI老照片视频</span>
                </div>
              </button>
            </li>
            <li class="menu-item-wrapper">
              <button
                :class="[
                  'menu-item',
                  { active: activeItem === 'AI二次元视频' },
                ]"
                @click="openwork5"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>AI二次元视频</span>
                </div>
              </button>
            </li>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === 'AI拥抱视频' }]"
                @click="openwork8"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>AI拥抱视频</span>
                </div>
              </button>
            </li>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === 'AI搞笑视频' }]"
                @click="openwork7"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>AI搞笑视频</span>
                </div>
              </button>
            </li>
            <li class="menu-item-wrapper">
              <button
                :class="['menu-item', { active: activeItem === 'AI原创高清' }]"
                @click="openwork4"
              >
                <div class="menu-item-content">
                  <video-icon />
                  <span>AI原创高清</span>
                </div>
              </button>
            </li>
            <el-divider />
            <li class="menu-item-wrapper" v-if="isLoggedIn">
              <button
                :class="['menu-item', { active: activeItem === '个人中心' }]"
                @click="navigateToPage('/myvideos')"
              >
                <div class="menu-item-content">
                  <UserRound />
                  <span>个人中心</span>
                </div>
              </button>
            </li>
            <li class="menu-item-wrapper" >
              <button
                :class="['menu-item', { active: activeItem === '会员中心' }]"
                @click="openVIP"
              >
                <div class="menu-item-content">
                  <Gem />
                  <span>会员中心</span>
                </div>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="left-bottom">
        <div class="upgrade-announcement" @click="showUpgradeMessage">升级公告</div>
        <div class="bottom-line"></div>
        <div class="official-platform" @mouseenter="showOfficialInfo = true">
          官方平台
        </div>
      </div>
      <div
        v-if="showOfficialInfo"
        class="official-info"
        @mouseenter="showOfficialInfo = true"
        @mouseleave="showOfficialInfo = false"
      >
        <h3>德娃AI官方体验入口</h3>
        <div class="qr-codes">
          <div class="qr-code">
            <img src="../assets/home/qr-code.png" alt="可灵 AI 公众号" />
            <p>德娃AI 公众号</p>
          </div>
          <div class="qr-code">
            <img src="../assets/home/qr-code1.png" alt="可灵 AI 快手号" />
            <p>德娃AI 抖音号</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边区域 -->
    <div class="page-content-wrapper">
      <!-- 视频制作方式选择对话框 -->
      <div class="right-header">
        <div class="toolbox">
          <div class="ipone" @click="openipone = true">
            <svg t="1729741345554" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="20004" width="30" height="30" style="margin-top: 3px;" >
              <path
                d="M625.834667 691.2h-256c-18.773333 0-34.133333 15.36-34.133334 34.133333s15.36 34.133333 34.133334 34.133334h256c18.773333 0 34.133333-15.36 34.133333-34.133334s-15.36-34.133333-34.133333-34.133333z"
                fill="#6639a6" p-id="20005"></path>
              <path
                d="M728.234667 102.4h-460.8c-56.490667 0-102.4 45.909333-102.4 102.4v614.4c0 56.490667 45.909333 102.4 102.4 102.4h460.8c56.490667 0 102.4-45.909333 102.4-102.4V204.8c0-56.490667-45.909333-102.4-102.4-102.4z m34.133333 716.8c0 18.773333-15.36 34.133333-34.133333 34.133333h-460.8c-18.773333 0-34.133333-15.36-34.133334-34.133333V204.8c0-18.773333 15.36-34.133333 34.133334-34.133333h460.8c18.773333 0 34.133333 15.36 34.133333 34.133333v614.4z"
                fill="#6639a6" p-id="20006"></path>
            </svg>
            <p style="color: #fff; font-size: 13px">手机端</p>
          </div>
          <!-- <div class="join-group">
            <a
              href="https://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=Hx2GXp1sWwOZ_4AlwYbckqsVRTQb5aL_&authKey=FL93UpSnf48nRJXLjKba%2BS0p1wH1jcUgTaN1uJWpO%2BUSW1%2BrWRb3Z56e0B0A5sds&noverify=0&group_code=775429432"
            >
              <img
                src="../assets/home/QQ.png"
                alt=""
                style="width: 20px; height: 20px; margin-right: 5px"
              />
              <p style="color: #fff; font-size: 13px">加入QQ交流群</p>
            </a>
          </div>
          <a href="/syhbs"> -->
            <!-- <div class="bookmark-site">
              <img
                src="../assets/home/syhbs.png"
                alt=""
                style="width: 20px; height: 20px; margin-right: 5px"
              />
              <p style="color: #fff; font-size: 13px">私有化独立部署</p>
            </div> -->
          <!-- </a> -->
          <div class="invite-users" @click="outerVisible4 = true">
            <img
              src="../assets/home/gift.png"
              alt=""
              style="width: 20px; height: 20px; margin-right: 5px"
            />
            <p style="color: #fff; font-size: 13px">邀请用户送积分</p>
          </div>
          <div
            class="login"
            plain
            @click="handleLoginClick"
            @mouseover="showSuspend = true"
            @mouseleave="handleMouseLeave"
          >
            <template v-if="isLoggedIn">
              <img
                src="../assets/home/login.jpg"
                alt="已登录"
                style="
                 float: right;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  margin-right: -40px;
                "
              />
            </template>
            <template v-else>
              <div class="not-login">注册/登录</div>
            </template>

            <!-- 悬停提示盒子 -->
            <div
              v-if="showSuspend && isLoggedIn"
              class="suspend"
              @mouseover="showSuspend = true"
              @mouseleave="showSuspend = false"
            >
              <template v-if="isLoggedIn">
                <div class="suspend-head">
                  <img src="../assets/home/login.jpg" alt="" />
                  <span>用户名:{{ username }}</span>
                  <a href="myvideos">个人资料&nbsp;></a>
                </div>
                <div class="suspend-line"></div>
                <div class="integral">
                  <div>
                    <span>{{ userid }}</span>
                    <span>用户ID</span>
                  </div>
                  <div>
                    <span>{{ drawNum }}</span>
                    <span>积分详情&nbsp;></span>
                  </div>
                </div>
                <div class="open-vip">
                  <div class="vip-content">
                    <div class="vip-text">
                      <div>升级更高等级会员，享折上折!</div>
                    </div>
                    <button class="vip-button" @click="dialogVisible = true">
                      开通
                    </button>
                  </div>
                </div>
                <div class="box-button">
                  <a href="/myvideos" style="color: #000">
                  <div class="button-item">
                    <svg
                      t="1726811265160"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="3361"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M623.3302914844444 203.89989148444442H97.74775296000001s-88.02860259555555 0-88.02860259555555 85.43952554666666v432.37578296888887c0 85.43952554666666 88.02860259555555 85.43952554666666 88.02860259555555 85.43952554666666h525.5825385244444s88.02860259555555 0 88.02860259555555-85.43952554666666V291.92849408000006c0-88.02860259555555-88.02860259555555-88.02860259555555-88.02860259555555-88.02860259555555zM983.2119307377778 247.9141922133333c-7.767230008888888-2.5890770488888886-15.534458879999999-2.5890770488888886-20.71261184 2.5890770488888886l-173.46812928 134.63198037333333c-5.17815296 5.17815296-7.767230008888888 10.35630592-7.7672288711111115 15.534458879999999v214.89335296000002c0 5.17815296 2.5890770488888886 12.945382968888888 7.7672288711111115 15.534460017777777l173.46812928 134.63197923555558c2.5890770488888886 2.5890770488888886 7.767230008888888 5.17815296 12.945381831111112 5.178154097777777 2.5890770488888886 0 5.17815296 0 10.356307057777778-2.5890770488888886 7.767230008888888-2.5890770488888886 10.35630592-10.35630592 10.35630592-18.123535928888888V266.03772814222225c0-7.767230008888888-5.17815296-15.534458879999999-12.945382968888888-18.123535928888888z"
                        fill="currentColor"
                        p-id="3362"
                      ></path>
                    </svg>
                    我的视频
                  </div>
                </a>
                  <div class="button-item" @click="showActivationDialog = true">
                    <svg
                      t="1726811329693"
                      class="icon"
                      viewBox="0 0 1152 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="9183"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M979.586776 1023.936004H172.405225A172.981189 172.981189 0 0 1 0 850.634835V732.754203c0-42.109368 25.150428-80.506968 63.996-97.913881a134.711581 134.711581 0 0 0 0-245.680644C25.214424 371.816761 0 333.419161 0 291.373789V173.301169A172.981189 172.981189 0 0 1 172.405225 0h807.117555A172.981189 172.981189 0 0 1 1151.928004 173.301169v117.816636c0 42.173364-25.214424 80.570964-64.123992 97.913881a134.775577 134.775577 0 0 0 0 245.872632c39.03756 17.342916 64.123992 55.804512 64.123992 97.913881v117.816636A172.981189 172.981189 0 0 1 979.586776 1023.936004zM172.405225 79.995c-51.1968 0-92.7942 41.853384-92.794201 93.306169V291.181801c0 10.687332 6.3996 20.15874 16.766952 24.830448a214.898569 214.898569 0 1 1 0 391.911506 27.262296 27.262296 0 0 0-16.830948 24.830448v117.880632c0 51.452784 41.5974 93.306168 92.794201 93.306169h807.181551c51.1968 0 92.7942-41.853384 92.7942-93.306169v-117.816636c0-10.687332-6.3996-20.222736-16.766952-24.830448A214.706581 214.706581 0 0 1 928.581964 511.968002c0-84.730704 49.788888-161.653897 126.904068-196.019749a27.262296 27.262296 0 0 0 16.830948-24.830448V173.301169c0-51.452784-41.5974-93.306168-92.7942-93.306169H172.341229z"
                        fill="currentColor"
                        p-id="9184"
                      ></path>
                      <path
                        d="M737.169927 411.494282H422.501594a39.933504 39.933504 0 0 1-39.805513-39.997501c0-22.014624 17.854884-39.933504 39.805513-39.933504h314.668333a39.933504 39.933504 0 1 1 0 79.995001z m0 270.767077H422.501594a39.933504 39.933504 0 0 1-39.805513-39.9975c0-22.07862 17.854884-39.9975 39.805513-39.997501h314.668333c21.950628 0 39.741516 17.91888 39.741516 39.997501a39.933504 39.933504 0 0 1-39.741516 39.9975z"
                        fill="currentColor"
                        p-id="9185"
                      ></path>
                    </svg>
                    <span>激活码兑换</span>
                  </div>

                  <div class="button-item" @click="outerVisible4 = true">
                    <svg
                      t="1727056941151"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="18151"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M946.688 447.488c-19.968-14.336-46.08-17.408-68.608-8.704l-8.192 3.072V166.4c0.512-44.032-34.816-80.896-78.848-81.408H238.592h-0.512c-44.032 1.024-79.36 37.888-78.336 81.408V430.08l-7.68-3.584C128.512 417.28 102.4 420.352 81.92 435.2c-18.944 13.824-30.208 36.352-30.208 59.904v363.008c0 19.456 7.68 37.888 21.504 52.224s32.256 21.504 51.712 21.504h779.264c19.456 0 37.888-7.68 51.712-21.504 13.824-13.824 21.504-32.256 21.504-51.712V507.392c0-23.552-11.264-46.08-30.72-59.904z m-732.16-281.6c0-13.824 10.752-25.6 24.576-26.112h550.912c13.824 0.512 25.088 11.776 24.576 26.112v297.472l-300.032 116.736-300.032-126.976V165.888z m708.096 692.224c0 5.12-2.048 9.728-5.632 13.312-3.584 3.584-8.192 5.632-12.8 5.632H124.928c-5.12 0-9.728-2.048-13.312-5.632-3.584-3.584-5.632-8.192-5.632-13.312v-363.52c0-6.144 2.56-11.776 7.68-14.848 3.072-2.048 6.656-3.584 10.24-3.584 2.048 0 4.096 0.512 6.144 1.024l373.76 157.696c6.656 2.56 13.824 3.072 20.48 0.512l373.76-145.408c5.632-2.048 11.776-1.536 16.384 2.048 5.12 3.584 8.192 9.216 8.192 15.36v350.72z"
                        fill="currentColor"
                        p-id="18152"
                      ></path>
                      <path
                        d="M540.672 475.136l92.16-90.112c2.048-1.024 3.584-2.56 5.12-4.608 22.528-24.064 31.232-57.344 24.576-88.576-7.168-35.328-34.816-64-70.144-72.704-27.136-6.656-54.784-0.512-76.8 15.36-21.504-15.872-49.664-22.016-76.8-15.36-34.816 8.704-62.464 37.376-69.632 72.192-6.144 31.232 2.048 64 23.552 87.552l0.512 0.512c1.024 1.536 2.048 2.56 3.584 4.096l93.184 91.136 0.512 0.512c7.168 7.168 16.384 10.752 25.6 10.752s17.92-3.584 24.576-10.752zM436.736 346.624c-1.024-1.024-1.536-2.048-2.56-3.072-10.24-10.752-13.824-25.6-10.752-39.936v-0.512c3.072-14.848 14.848-27.136 29.184-30.72 15.872-4.096 31.744 2.56 40.96 16.384a27.74528 27.74528 0 0 0 46.08 0c8.704-13.312 25.088-19.968 40.448-16.384 14.848 3.584 26.112 15.872 29.696 31.232 3.072 12.8 0 26.112-8.192 36.864l-1.536 1.536c-1.536 1.024-2.56 2.56-4.096 3.584l-79.872 78.336-79.36-77.312z"
                        fill="currentColor"
                        p-id="18153"
                      ></path>
                    </svg>
                    <span>邀请用户</span>
                  </div>
                  <div class="button-item" :plain="true" @click="handleLogout">
                    <svg
                      t="1726811042569"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1444"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M952.532295 495.269967 767.686357 310.423005c-9.060353-9.060353-23.75196-9.060353-32.81743 0l-37.363979 37.362956c-9.060353 9.060353-9.060353 23.757077 0 32.81743l81.538061 81.538061L407.384337 462.141452c-12.812817 0-23.199375 10.386558-23.199375 23.199375l0 52.845579c0 12.815887 10.386558 23.204491 23.199375 23.204491l371.479593 0-81.538061 81.538061c-9.060353 9.060353-9.060353 23.757077 0 32.81743l37.368072 37.363979c9.060353 9.05933 23.75503 9.05933 32.815383 0l147.653875-147.653875c0-0.005117 0.005117-0.005117 0.005117-0.005117l37.368072-37.368072C961.592648 519.020904 961.592648 504.33032 952.532295 495.269967L952.532295 495.269967zM634.083499 64.754816l-499.803213 0c-38.441521 0-69.608358 31.166837-69.608358 69.608358l0 754.806002c0 38.446637 31.166837 69.608358 69.608358 69.608358l499.803213 0c38.441521 0 69.608358-31.16172 69.608358-69.608358l0-97.937566c0-12.811794-10.386558-23.204491-23.204491-23.204491l-50.29243 0c-12.812817 0-23.205515 10.392698-23.205515 23.204491l0 37.257555c0 34.328853 0 34.328853-34.791387 34.328853L195.199751 862.818017c-34.801621 0-34.801621 0.00614-34.801621-34.806737L160.39813 194.712657c0-34.900881-0.074701-34.802644 34.801621-34.802644l376.99726 0c34.798551 0 34.791387 0.285502 34.791387 34.329876l0 38.353516c0 12.815887 10.392698 23.204491 23.205515 23.204491l50.29243 0c12.817933 0 23.204491-10.388605 23.204491-23.204491L703.690834 134.363174C703.691857 95.921653 672.52502 64.754816 634.083499 64.754816L634.083499 64.754816zM634.083499 64.754816"
                        fill="currentColor"
                        p-id="1445"
                      ></path>
                    </svg>
                    <span>退出登录</span>
                  </div>
                </div>
              </template>
            </div>
            <!-- 激活码兑换对话框 -->
            <div v-if="showActivationDialog" class="dialog-overlay" @click="closeDialog"></div>
            <el-dialog
              title="激活码兑换"
              v-model="showActivationDialog"
              width="400px"
              :close-on-click-modal="false"
              class="activation-code-dialog"
            >
              <div>
                <el-input
                  v-model="activationCode"
                  placeholder="请输入激活码"
                  class="activation-code-input"
                ></el-input>
              </div>
              <div class="dialog-actions">
                <el-button type="primary" @click="activateCode">激活</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="hot-recommendation">
        <div class="hot-header">
          <video
            autoplay
            muted
            loop
            class="background-video left-background"
            style="border-radius: 10px"
          >
            <source src="../assets/home/video/banner.mp4" type="video/mp4" />
          </video>
          <h2>德娃AI &nbsp;视频创作新引擎 &nbsp;&nbsp;开启视觉奇幻之旅</h2>
        </div>
        <div class="search-container">
          <div class="search-bar">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="请输入关键词"
              class="search-input"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="search-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <div class="categories">
            <button
              v-for="category in categories"
              :key="category"
              class="category-button"
              :class="{ active: category === activeCategory }"
              @click="setActiveCategory(category)"
            >
              {{ category }}
            </button>
          </div>
        </div>
        <div class="big-box">
          <div class="image-grid">
            <div
              v-for="(item, index) in recommendVideo"
              :key="index"
              class="image-container"
            >
              <div
                class="video-container"
                v-if="item.imgsrc === 'https://video.genceai.com/uploads/'"
              >
                <!-- 图片为空显示视频 -->
                <div class="video-wrapper">
                  <video
                    @mouseenter="playVideo($event.target)"
                    :ref="
                      (el) => {
                        if (el) videoRefs[index] = el;
                      }
                    "
                    @mouseleave="pauseVideo($event.target)"
                    @timeupdate="updateProgress(index)"
                    @loadedmetadata="updateDuration(index)"
                    loop
                    muted
                    @click="openModal(item.videosrc, item.alt)"
                  >
                    <source
                      :src="item.videosrc.replace('http:', 'https:')"
                      type="video/mp4"
                    />
                  </video>
                  <div class="overlay"></div>

                  <div class="bottom-controls">
                    <div class="duration">
                      {{ formatTime(currentTimes[index] || 0) }}/{{
                        formatTime(durations[index] || 0)
                      }}
                    </div>
                    <div class="copy">
                      <!-- <div class="copy-a2">{{ item.alt }}</div> -->
                    </div>
                    <div class="copy-a3" @click="outerVisible4 = true">
                      <svg
                        t="1726711646686"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="20035"
                        width="20"
                        height="20"
                        style="cursor: pointer"
                      >
                        <path
                          d="M1009.777778 503.466667l-443.733334-455.111111c-5.688889-5.688889-11.377778 0-11.377777 5.688888v267.377778C8.533333 409.6 2.844444 918.755556 17.066667 932.977778c0 0 45.511111-48.355556 164.977777-113.777778 85.333333-48.355556 224.711111-85.333333 369.777778-102.4v261.688889c0 8.533333 11.377778 11.377778 14.222222 5.688889l443.733334-480.711111z m-398.222222 358.4v-199.111111l-36.977778-2.844445c-221.866667 8.533333-378.311111 73.955556-497.777778 156.444445 76.8-275.911111 267.377778-403.911111 466.488889-438.044445l68.266667-2.844444v-199.111111l312.888888 312.888888s8.533333 5.688889 8.533334 14.222223-8.533333 14.222222-8.533334 14.222222l-312.888888 344.177778z"
                          fill="#cdcdcd"
                          p-id="20036"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="image-container" v-else>
                <div class="image-wrapper">
                  <img
                    :src="item.imgsrc"
                    :alt="item.alt"
                    @click="openModal(item.videosrc, item.alt)"
                  />
                  <div class="overlay"></div>

                  <div class="bottom-controls1">
                    <div class="copy">
                      <div class="copy-a2">{{ item.alt }}</div>
                    </div>
                    <div class="copy-a3" @click="outerVisible4 = true">
                      <svg
                        t="1726711646686"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="20035"
                        width="20"
                        height="20"
                        style="cursor: pointer"
                      >
                        <path
                          d="M1009.777778 503.466667l-443.733334-455.111111c-5.688889-5.688889-11.377778 0-11.377777 5.688888v267.377778C8.533333 409.6 2.844444 918.755556 17.066667 932.977778c0 0 45.511111-48.355556 164.977777-113.777778 85.333333-48.355556 224.711111-85.333333 369.777778-102.4v261.688889c0 8.533333 11.377778 11.377778 14.222222 5.688889l443.733334-480.711111z m-398.222222 358.4v-199.111111l-36.977778-2.844445c-221.866667 8.533333-378.311111 73.955556-497.777778 156.444445 76.8-275.911111 267.377778-403.911111 466.488889-438.044445l68.266667-2.844444v-199.111111l312.888888 312.888888s8.533333 5.688889 8.533334 14.222223-8.533333 14.222222-8.533334 14.222222l-312.888888 344.177778z"
                          fill="#cdcdcd"
                          p-id="20036"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="no-more-data-container"
            v-if="recommendVideo.length === 0"
          >
            <div class="icon-container">
              <svg
                class="icon1"
                viewBox="0 0 160 140"
                xmlns="http://www.w3.org/2000/svg"
              >
                <!-- Larger Shadow -->
                <path d="M16 128 L88 104 L160 128 L88 152 Z" fill="#1A202C" />
                <path d="M16 72 L88 48 L160 72 L88 96 Z" fill="#2D3748" />
                <!-- Main box -->
                <path d="M8 112 L80 88 L152 112 L80 136 Z" fill="#4A5568" />
                <path d="M8 56 L80 32 L152 56 L80 80 Z" fill="#718096" />
                <path d="M8 56 L80 80 L80 136 L8 112 Z" fill="#2D3748" />
                <path d="M152 56 L80 80 L80 136 L152 112 Z" fill="#4A5568" />
                <path d="M8 56 L80 32 L80 80 L8 56 Z" fill="#A0AEC0" />
                <path d="M152 56 L80 32 L80 80 L152 56 Z" fill="#718096" />
              </svg>
            </div>
            <p class="no-more-data-text">暂无更多</p>
          </div>
        </div>
      </div>
      <el-dialog title="创作" width="400px">
        <div>
          <p>欢迎来到创作中心！</p>
          <el-button type="primary" @click="createContent">立即创作</el-button>
        </div>
      </el-dialog>
    </div>
  </main>
  <div
    v-if="showVideoOptionsDialog"
    class="dialog-overlay"
    @click="closeDialog"
  ></div>
  <el-dialog
    title="选择视频制作的渲染引擎"
    v-model="showVideoOptionsDialog"
    style="
      height: 400px;
      border-radius: 20px;
      background: #bdc3c7;
      background: -webkit-linear-gradient(
        to top,
        rgba(189, 199, 193, 0.6),
        rgba(32, 43, 55, 0.9)
      );
      background: linear-gradient(to right top);
      box-shadow: 2px 3px 20px #444f5a;
      margin-top: 250px;
      width: 700px;
    "
  >
    <div data-v-7eef648b="" class="banner">
      <span
        @click="openwork1"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">runway类型</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          效果稳定。只能输出16：9的10秒视频
        </span>
      </span>
      <span
        @click="openwork2"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">luma</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          效果不稳定。可选尺寸输出
        </span>
      </span>
      <br />
      <span
        @click="openwork3"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">kling</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          视频渲染效果最好但渲染时间长
        </span>
      </span>
      <span
        @click="openwork1"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">pik</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          pik渲染效果较差，但渲染速度快
        </span>
      </span>
    </div>
  </el-dialog>
  <div
    v-if="showVideoOptionsDialog1"
    class="dialog-overlay"
    @click="closeDialog"
  ></div>
  <el-dialog
    title="选择视频制作的渲染引擎"
    v-model="showVideoOptionsDialog1"
    style="
      height: 400px;
      border-radius: 20px;
      background: #bdc3c7;
      background: -webkit-linear-gradient(
        to top,
        rgba(189, 199, 193, 0.6),
        rgba(32, 43, 55, 0.9)
      );
      background: linear-gradient(to right top);
      box-shadow: 2px 3px 20px #444f5a;
      margin-top: 250px;
      width: 700px;
    "
  >
    <div data-v-7eef648b="" class="banner">
      <span
        @click="openwork5"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">runway类型</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          效果稳定。只能输出16：9的10秒视频
        </span>
      </span>
      <span
        @click="openwork5"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">luma</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          效果不稳定。可选尺寸输出
        </span>
      </span>
      <br />
      <span
        @click="openwork5"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">kling</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          视频渲染效果最好但渲染时间长
        </span>
      </span>
      <span
        @click="openwork5"
        data-v-867451f0=""
        data-v-7eef648b=""
        class="card"
        style="
          background: linear-gradient(
            135deg,
            rgba(82, 255, 186, 0.04) 9.27%,
            rgba(35, 250, 236, 0.04) 46.96%,
            rgba(0, 170, 255, 0.04) 88.5%
          );
        "
      >
        <svg
          data-v-867451f0=""
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="round-btn"
        >
          <rect
            x="0.833252"
            y="0.5"
            width="35"
            height="35"
            rx="17.5"
            stroke="url(#paint0_linear_950_14379)"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4106 13.0337C19.0852 12.7082 19.0852 12.1806 19.4106 11.8552C19.7361 11.5297 20.2637 11.5297 20.5892 11.8552L26.1447 17.4107C26.4702 17.7362 26.4702 18.2638 26.1447 18.5892L20.5892 24.1448C20.2637 24.4702 19.7361 24.4702 19.4106 24.1448C19.0852 23.8193 19.0852 23.2917 19.4106 22.9663L23.5436 18.8333H11.111C10.6508 18.8333 10.2777 18.4602 10.2777 18C10.2777 17.5397 10.6508 17.1666 11.111 17.1666H23.5436L19.4106 13.0337Z"
            fill="url(#paint1_linear_950_14379)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_950_14379"
              x1="0.333252"
              y1="0"
              x2="36.3333"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_950_14379"
              x1="26.3888"
              y1="11.6111"
              x2="13.9468"
              y2="27.2988"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0926677" stop-color="#52FFBA"></stop>
              <stop offset="0.469568" stop-color="#23FAEC"></stop>
              <stop offset="0.885" stop-color="#00AAFF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h2 data-v-867451f0="" style="pointer-events: none">
          <span data-v-867451f0="">pik</span>
        </h2>
        <span
          data-v-867451f0=""
          style="pointer-events: none; margin-left: -20px"
        >
          pik渲染效果较差，但渲染速度快
        </span>
      </span>
    </div>
  </el-dialog>
  <div v-if="outerVisible2" class="dialog-overlay" @click="closeDialog"></div>
  <el-dialog
    v-model="outerVisible2"
    width="700"
    style="
      height: 500px;
      border-radius: 20px;
      overflow: hidden;
      background: #bdc3c7;
      background: -webkit-linear-gradient(
        to top,
        rgba(189, 199, 193, 0.6),
        rgba(32, 43, 55, 0.9)
      );
      background: linear-gradient(
        to right top,
        rgba(231, 244, 237, 0.6),
        rgba(32, 43, 55, 0.8),
        rgba(32, 43, 55, 0.9)
      );
      box-shadow: 2px 3px 20px #444f5a;
    "
  >
    <div class="login-dialog">
      <div class="left-section">
        <img src="../assets/home/login-background.png" alt="" />
      </div>
      <div class="right-section">
        <div class="header">
          <h3>欢迎登录</h3>
          <p>登录后每天领取免费德娃AI值</p>
        </div>
        <div class="tabs">
          <span class="active">手机登录</span>
        </div>
        <form>
          <div class="input-group">
            <input v-model="username" type="text" placeholder="请输入手机号—新用户自动注册" />
          </div>
          <div class="input-group">
            <input
              v-model="password"
              type="password"
              id="password-input"
              placeholder="请输入密码"
              required
            />
          </div>
          <p class="terms">
            登录即代表同意 <a href="#">《用户协议》</a> 和
            <a href="#">《隐私政策》</a>
          </p>
          <button type="button" class="submit-btn" @click="onSubmit()">
            登录（新用户自动注册）
          </button>
        </form>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    v-model="outerVisible3"
    width="1100"
    style="
      height: 520px;
      border-radius: 20px;
      background-color: rgb(0, 0, 0, 0);
    "
  >
    <div class="pop-up">
      <div class="pop-left">
        <div class="studio-info">
          <video
            :src="adVideo.replace('http:', 'https:')"
            controls
            width="550"
            height="340"
          ></video>
        </div>
      </div>
      <div class="pop-right">
        <div class="creation-details">
          <span>创作详情</span>
          <div class="call-word">
            {{ adalt }}
          </div>
          <div class="cta-container">
            <button class="main-button" @click="openwork5">创作同款</button>
            <div class="icon-buttons">
              <button class="icon-button" @click="handleLike">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  :fill="isLiked ? '#6C5CE7' : 'none'"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
                  ></path>
                </svg>
              </button>
              <button class="icon-button" @click="handleShare">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="18" cy="5" r="3"></circle>
                  <circle cx="6" cy="12" r="3"></circle>
                  <circle cx="18" cy="19" r="3"></circle>
                  <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                  <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

  <div v-if="outerVisible4" class="dialog-overlay" @click="closeDialog"></div>

  <el-dialog v-model="outerVisible4" width="720px" style="
      /* height: 780px; */
     top: 180px;
      border-radius: 20px;
      background-color: #1a202c;
      box-shadow: 2px 3px 20px #444f5a;
    ">
    <div class="invite">
      <h1 class="title">邀新即享 超值会员权益</h1>

      <p class="description">
        即日起至12月31日，期间 每邀请1位新用户并激活，即享受如下权益：
      </p>
      <ol class="benefits-list">
        <li>
          邀请人&被邀请人，均获得
          <span class="highlight">500德娃AI值 <span class="cashout" @click="customerService = true">（联系客服获取）</span></span>
        </li>
        
        <li>
          被邀请人充值积分或会员，邀请人即可获得
          <span class="highlight">充值金额的20%返现</span> (无上限累加)
          <span class="cashout" @click="customerService = true">联系客服</span>
        </li>
      </ol>

      <div class="referral-input">
       <p>https://aigc.genceai.com/</p>
        <button @click="copyText">复制</button>
      </div>

    </div>
  </el-dialog>
  <div v-if="openipone" class="dialog-overlay" @click="closeDialog"></div>

<el-dialog v-model="openipone" width="300px" style="
    /* height: 200px; */
   top: 240px;
    border-radius: 20px;
    background-color: #1a202c;
    box-shadow: 2px 3px 20px #444f5a;
  ">
  <img src="../assets/home/ipone-qr.png" alt="">
</el-dialog>
  <div v-if="customerService" class="dialog-overlay" @click="closeDialog"></div>

  <el-dialog v-model="customerService" width="200px" style="
      /* height: 200px; */
     top: 240px;
      border-radius: 20px;
      background-color: #1a202c;
      box-shadow: 2px 3px 20px #444f5a;
    ">
    <img src="../assets/syhbs/qr-code.jpeg" alt="">
  </el-dialog>
  <div v-if="customerService1" class="dialog-overlay" @click="closeDialog"></div>

  <el-dialog v-model="customerService1" width="400px" style="
      /* height: 400px; */
     top: 100px;
      border-radius: 20px;
      background-color: #1a202c;
      box-shadow: 2px 3px 20px #444f5a;
    ">
    <img src="../assets/syhbs/qr-code.jpeg" alt="">
  </el-dialog>
  <div v-if="dialogVisible" class="dialog-overlay" @click="closeDialog"></div>
  <el-dialog class="vip-center" v-model="dialogVisible" width="1200" :style="{
    height: '800px',
    marginTop: '28px',
    marginBottom: '28px',
    borderRadius: '20px',
    backgroundImage: `url(${require('@/assets/home/vipbackground.png')}), 
        linear-gradient(
          to top,
          rgba(189, 199, 193, 0.6),
          rgba(32, 43, 55, 0.9)
        ),
        linear-gradient(
          to right top,
          rgba(231, 244, 237, 0.6),
          rgba(32, 43, 55, 0.8),
          rgba(32, 43, 55, 0.9)
        )`,
    backgroundSize: '100% 50%, 100% 100%, 100% 100%',
    backgroundPosition: 'top center, center, center',
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
    backgroundColor: '#bdc3c7',
    boxShadow: '2px 3px 20px #444f5a',
  }">
    <template #title>
      <div class="vip-title">德娃AI 专为视频而诞生</div>
    </template>
    <p class="subtitle">
      选择合适的套餐，你也可以直接 <el-link type="primary">购买德娃AI值</el-link>
    </p>

    <el-radio-group v-model="selectedOption" class="subscription-options">
      <el-radio-button v-for="option in subscriptionOptions" :key="option.value" :label="option.value">
        {{ option.label }}
      </el-radio-button>
    </el-radio-group>

    <el-row :gutter="20" class="membership-tiers">
      <el-col :xs="24" :sm="24" :md="6" v-for="(tier, index) in filteredProList" :key="tier.id">
        <el-card :class="['tier-card', `tier-card-${index + 1}`]">
          <template #header>
            <div class="tier-header">
              <h3>{{ tier.proname }}</h3>
              <el-tag type="warning" effect="dark" class="discount-tag">首购低至5折</el-tag>
            </div>
          </template>
          <div class="tier-price">
            <span class="current-price">¥{{ tier.money }}</span>
            <span class="original-price">/{{ tier.time }}天</span>
          </div>
          <p v-if="tier.proname !== '非会员'" class="tier-description">
            倾心为视频而生，捕捉每一个精彩瞬间，绽放视频魅力新光芒。
          </p>
          <div v-if="tier.proname !== '非会员'" class="vip-list"></div>
          <div v-if="tier.proname !== '非会员'" class="credit-info">
          </div>
          <p class="credit-description">{{}}</p>
          <ul class="feature-list">
            <li v-for="feature in membershipTiers[index].features" :key="feature">
              <el-icon>
                <Check />
              </el-icon>
              {{ feature }}
            </li>
            <li v-if="index === 0">
              <el-icon>
                <Check />
              </el-icon>
              <span class="credit-label">
                每日限领<span class="credit-amount">100</span>份激活码
              </span>
            </li>
            <li >
              <el-icon>
                <Check />
              </el-icon>
              <span class="credit-label">每日可用<span class="credit-amount">{{ tier.permoney
                  }}</span>德娃AI值</span>
            </li>
            <li >
              <el-icon>
                <Check />
              </el-icon>
              <span  class="credit-label">总计<span class="credit-amount">{{ tier.totalmoney }}</span>德娃AI值</span>
            </li>
            <li v-if="index === 0">
              <el-icon>
                <Check />
              </el-icon>
              <span class="credit-label" style="cursor: pointer;" @click="customerService1 = true">
                请<span class="cashout">联系客服</span>领取
              </span>
            </li>
          </ul>
          <el-button type="primary" class="subscribe-button" :class="index === 0 ? 'subscribe-button' : ''"
            @click="index === 0 ? customerService1 = true : fetchData(tier.id, openid)">
            {{ index === 0 ? "领取德娃AI值" : "特惠订阅" }}
          </el-button>
          <p v-if="tier.proname !== '非会员'" class="first-purchase-note">
            首购优惠每个用户仅限一次
          </p>
        </el-card>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import axios from "axios";
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import {
  HomeIcon,
  LightbulbIcon,
  VideoIcon,
  FileTextIcon,
  RadioIcon,
  FileSearchIcon,
  FileCheckIcon,
  BarChart2Icon,
  BriefcaseIcon,
  ChevronRightIcon,
  Gem,
  UserRound,
} from "lucide-vue-next";
const copyText = async () => {
  const text = 'https://aigc.genceai.com/'; // 需要复制的文本
  try {
    await navigator.clipboard.writeText(text); // 使用剪贴板 API
    alert('已复制到剪贴板！');
  } catch (err) {
    console.error('复制失败:', err);
  }
}

const showUpgradeMessage = () => {
  ElMessage({
    message: "暂无升级公告",
    type: "warning",
  });
};

const activeItem = ref("视频广场");
const hoveredItem = ref(null);
const showGlow = ref(false);
const glowStyle = ref({});

const navigateToPage = (route) => {
  router.push(route);
  activeItem.value = route.split("/")[1] || "首页";
};

const handleMouseMove = (event) => {
  showGlow.value = true;
  glowStyle.value = {
    top: `${event.clientY}px`,
    left: `${event.clientX}px`,
  };
};
const handleMouseLeave1 = () => {
  showGlow.value = false;
};

const referralCode = ref("");
const users = ref([]);
const applyReferralCode = () => {
  console.log("应用推荐码:", referralCode.value);
  referralCode.value = "";
};
const showBookmarkTip = () => {
  ElMessage({
    message: "请按下 Ctrl+D 将此页添加到收藏夹",
    type: "warning",
  });
};
import "./css/home.css";
const videoRefs = ref([]);
const currentTimes = ref([]);
let adVideo = ref("");
let adalt = ref("");
const durations = ref([]);
const playVideo = (el) => {
  if (el) {
    el.play();
  }
};

const pauseVideo = (el) => {
  if (el) {
    el.pause();
  }
};
const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const updateProgress = (index) => {
  if (videoRefs.value[index]) {
    currentTimes.value[index] = videoRefs.value[index].currentTime;
  }
};

const updateDuration = (index) => {
  if (videoRefs.value[index]) {
    durations.value[index] = videoRefs.value[index].duration;
  }
};

onMounted(() => {
  videoRefs.value = new Array(recommendVideo.length);
  currentTimes.value = new Array(recommendVideo.length).fill(0);
  durations.value = new Array(recommendVideo.length).fill(0);
});

const openModal = (videosrc, alt) => {
  console.log(videosrc, alt);
  outerVisible3.value = true;
  adVideo = videosrc;
  adalt = alt;
};

const openipone = ref(false); // 控制对话框显示
const showActivationDialog = ref(false); // 控制对话框显示
const activationCode = ref(""); // 存储激活码

// 会员

import { Check } from "@element-plus/icons-vue";

const dialogVisible = ref(false);
const selectedOption = ref("quarterly");

const handleClose = (done) => {
  ElMessage({
    message: "关闭会员弹窗",
    type: "info",
  });
  done();
};

const filteredProList = computed(() => {
  if (selectedOption.value === "quarterly") {
    return [
      proList.value[0],
      proList.value[1],
      proList.value[2],
      proList.value[3],
    ];
  } else if (selectedOption.value === "monthly") {
    return [
      proList.value[4],
      proList.value[5],
      proList.value[6],
      proList.value[7],
    ];
  } else if (selectedOption.value === "single") {
    return [
      proList.value[8],
      proList.value[9],
      proList.value[10],
      proList.value[11],
    ];
  } else {
    return [];
  }
});

const subscriptionOptions = [
  { label: "连续包月", value: "quarterly" },
  { label: "连续包季", value: "monthly" },
  { label: "连续包年", value: "single" },
];

const membershipTiers = ref([
  {
    name: "非会员",
    price: 0,
    originalPrice: 0,
    nextSeasonPrice: 0,
    discountPercentage: 0,
    description: "",
    creditAmount: 0,
    creditDescription: "",
    features: [
     
    ],
  },
  {
    name: "黄金会员",
    price: 99,
    originalPrice: 198,
    nextSeasonPrice: 152,
    discountPercentage: 77,
    creditAmount: 660,
    creditDescription: "约生成 3300 张图片或 66 个标准视频",
    features: [
      "专享生成快速通道",
      "图片、视频去水印",
      "高品质视频生成",
      "视频延长、大幅运镜等专享功能",
      "生成图片专享画质增强",
    ],
  },
  {
    name: "铂金会员",
    price: 399,
    originalPrice: 798,
    nextSeasonPrice: 614,
    discountPercentage: 77,
    creditAmount: 3000,
    creditDescription: "约生成 15000 张图片或 300 个标准视频",
    features: [
      "专享生成快速通道",
      "图片、视频去水印",
      "高品质视频生成",
      "视频延长、大幅运镜等专享功能",
      "生成图片专享画质增强",
      "新功能优先体验",
    ],
  },
  {
    name: "钻石会员",
    price: 999,
    originalPrice: 1998,
    nextSeasonPrice: 1538,
    discountPercentage: 77,
    creditAmount: 8000,
    creditDescription: "约生成 40000 张图片或 800 个标准视频",
    features: [
      "专享生成快速通道",
      "图片、视频去水印",
      "高品质视频生成",
      "视频延长、大幅运镜等专享功能",
      "生成图片专享画质增强",
      "新功能优先体验",
    ],
  },
]);


const userid = ref(0);
const getUserinfo = () => {
  // 获取用户信息
  axios
    .get(
      "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
        openid.value
    )
    .then((res) => {
      console.log(res.data.data);
      drawNum.value = res.data.data.drawNum;
      userid.value = res.data.data.id;
      console.log(drawNum.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const activateCode = () => {
  if (!activationCode.value.trim()) {
    alert("请输入有效的激活码");
    return;
  }
  // 在这里添加激活码处理逻辑，例如向后端发送请求
  axios
    .post(
      `/genceai/?s=/api/api/run/m/xz_aivideo/do/checkcode/uniacid/10005&code=${activationCode.value}&openid=${openid.value}`
    )
    .then((res) => {
      console.log(res);
      console.log("激活码:", activationCode.value);
      if (res.data.message == "success") {
        ElMessageBox.alert("激活成功", "提示", {
          confirmButtonText: "确定",
          });
          } else {
            ElMessageBox.alert("激活失败，请检查激活码是否正确", "提示", {
              confirmButtonText: "确定",
              });
          }
      getUserinfo();
      showActivationDialog.value = false; // 激活成功后关闭对话框
    })
    .catch((err) => {
      console.log(err);
      alert("激活失败，请检查激活码是否正确");
    });
};
const router = useRouter();
const username = ref("");
const password = ref("");
const openid = ref("");
const drawNum = ref(0);
const outerVisible2 = ref(false);
const outerVisible3 = ref(false);
const outerVisible4 = ref(false);
const customerService = ref(false);
const customerService1 = ref(false);
const showOfficialInfo = ref(false); // 控制官方平台的显示
const showSuspend = ref(false); // 控制suspend盒子的显示
const isLoggedIn = ref(false); // 初始化为未登录状态
const showTooltip = ref(false); // 控制悬停提示的显示状态
const handleMouseLeave = () => {
  showSuspend.value = false;
};
const handleLogout = () => {
  isLoggedIn.value = false; // 设置未登录状态
  username.value = ""; // 清空用户名
  password.value = ""; // 清空密码
  openid.value = ""; // 清空openid
  userid.value = 0; // 清空userid
  window.localStorage.removeItem("user"); // 清除存储的用户数据
  ElMessage({
    message: "已成功退出登录",
    type: "success",
  });
};

const handleLoginClick = () => {
  if (!isLoggedIn.value) {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
  }
};

const onSubmit = () => {
  // 表单验证
  if (!username.value.trim() || !password.value.trim()) {
    alert("请输入用户名和密码！");
    return;
  }

  // 实现登录逻辑
  axios
    .post(
      `/genceai/?s=/api/api/run/m/xz_aivideo/do/adduser/uniacid/10005&username=${username.value}&password=${password.value}`
    )
    .then((res) => {
      console.log(username.value, password.value);
      console.log(res.data.data);

      if (res.data.message == "登录成功" || res.data.message == "注册成功") {
        isLoggedIn.value = true; // 登录成功后，设置为已登录状态
        openid.value = res.data.data.openid;
        console.log(openid.value);
        if (res.data.message == "登录成功") {
          ElMessage({
            message: "登录成功",
            type: "success",
          });

        } else {
          // 注册成功的逻辑提示
          ElMessage({
            message: "注册成功",
            type: "success",
          });
        }
        axios
          .get(
            "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
            openid.value
          )
          .then((res) => {
            console.log(res.data.data);
            drawNum.value = res.data.data.drawNum;
            console.log(drawNum.value);

          })
          .catch((err) => {
            console.log(err);
          });

        const userData = {
          username: username.value,
          password: password.value,
          openid: openid.value,
          drawNum: drawNum.value,
        };
        console.log("userdata:", userData);
        window.localStorage.setItem("user", JSON.stringify(userData));
        getUserinfo(); // 获取用户信息
        // 关闭登录弹窗
        outerVisible2.value = false;
        // 禁用点击登录按钮
        showTooltip.value = true; // 显示登录后的提示信息
      } else {
        ElMessage({
          message: "用户名或密码错误",
          type: "error",
      }

      )};
    })  
    .catch((err) => {
      console.log(err);
    });
};
const showVideoOptionsDialog = ref(false); // 控制视频制作方式选择对话框的显示
const showVideoOptionsDialog1 = ref(false); // 控制视频制作方式选择对话框的显示

onMounted(() => {
  window.handleLogin = () => {
    const usernameValue = document.getElementById("username-input").value;
    const passwordValue = document.getElementById("password-input").value;
    username.value = usernameValue;
    password.value = passwordValue;
    onSubmit();
  };

  const userData = JSON.parse(window.localStorage.getItem("user"));
  if (userData) {
    isLoggedIn.value = true; // 设置为已登录状态
    username.value = userData.username; // 可以选择展示用户名等信息
    openid.value = userData.openid; // 获取openid // 这里可以调用获取用户信息的函数
    getUserinfo(); // 获取用户信息
  } else {
    // 未登录状态
    isLoggedIn.value = false;
  }
});

import { getRecommendVideo } from "./api/api.js";

const page = ref(1);
const isLoading = ref(false);
const hasMore = ref(true);
const tempVideoData = ref([]); // 用于临时存储视频数据

// 获取推荐视频
const getRecommend = async (pageNum) => {
  if (isLoading.value || !hasMore.value) return; // 避免重复请求

  isLoading.value = true;

  const res = await getRecommendVideo(pageNum);
  if (!Array.isArray(res)) {
    console.error("getRecommendVideo 返回的结果不是一个数组", res);
    page.value++;
    isLoading.value = false; // 锁定结束
    return; // 直接返回
  }

  if (res.length === 0) {
    hasMore.value = false; // 没有更多数据
    isLoading.value = false; // 锁定结束
    return; // 直接返回，不再进行后续操作
  }

  // 将获取到的数据推入临时数组
  for (let i = 0; i < res.length; i++) {
    tempVideoData.value.push({
      videosrc: res[i].videopath_ol,
      imgsrc: res[i].imgpath.includes("https://video.genceai.com/uploads/")
        ? res[i].imgpath
        : "https://video.genceai.com/uploads/" + res[i].imgpath,
      alt: res[i].musicword,
    });
  }

  console.log("临时视频数据", tempVideoData.value);

  isLoading.value = false; // 数据获取完成，解锁
  page.value++; // 数据获取成功后才增加页码
};

// 自动加载前7页的数据
const loadInitialData = async () => {
  for (let i = 1; i <= 7; i++) {
    await getRecommend(i);
  }

  // 在所有页面获取完毕后，将临时数据赋值给最终的数据
  recommendVideo.value = tempVideoData.value;
};

const loadMoreData = () => {
  const onScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100 &&
      hasMore.value // 确保仍然有更多数据
    ) {
      getRecommend(page.value);
    }
  };

  window.addEventListener("scroll", onScroll);

  // 在组件卸载时移除事件监听
  onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
  });
};




// 组件挂载时加载初始数据和设置滚动事件监听
onMounted(() => {
  loadInitialData();
  loadMoreData();
  getProlist();
});

// 用于存储套餐列表数据
const proList = ref([]);
//返回数据格式：
//             "id": 20,//套餐编号
//             "proname": "周卡",//套餐名称
//             "pos": 1, //排序
//             "money": 10,  //价格
//             "totalmoney": 1000,  //总积分
//             "permoney": 1000,  //每日限用积分
//             "uid": 10005,
//             "time": 7,   //有效期
//             "my": 0,
//             "payurl": null,
//             "install": 1
// 调用获取套餐列表接口
const getProlist = () => {
  axios
    .get("/genceai/?s=/api/api/run/m/xz_aivideo/do/Prolist/uniacid/10005")
    .then((res) => {
      if (res.data.errno === 0) {
        proList.value = res.data.data; // 将获取的数据分配给 proList
      } else {
        console.error(res.data.message);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

//显示支付二维码的接口
const htmlContent = ref("");

const fetchData = async (proid, openid) => {
  if (!isLoggedIn.value) {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  } else {
    try {
      console.log("openid:", openid);

      const response = await axios.get(
        "/genceai/hpjapi/payment.php?path=https%3A%2F%2Ftest.genceai.com",
        {
          params: {
            proid: proid,
            openid: openid,
            uniacid: "10005",
          },
        }
      );

      // 使用路由跳转，并将 HTML 数据传递至目标页面
      router.push({
        name: "pay",
        query: { htmlContent: response.data }, // 将 HTML 数据作为路由参数传递
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};

//搜索
const searchQuery = ref("");
const activeCategory = ref("全部");

const categories = [
  "全部",
  "写实",
  "头像",
  "美女",
  "动漫",
  "3D",
  "电商",
  "国风",
  "建筑",
  "室内",
  "logo",
  "风景",
  "IP形象",
  "手绘",
];

const recommendVideo = computed(() => {
  return tempVideoData.value.filter((item) => {
    // 判断描述词是否包含搜索查询
    const matchesSearch = item.alt
      .toLowerCase()
      .includes(searchQuery.value.toLowerCase());

    // 判断是否选择"全部"类别，或描述词是否匹配当前活动类别
    const matchesCategory =
      activeCategory.value === "全部" ||
      item.alt.toLowerCase().includes(activeCategory.value.toLowerCase());
    return matchesSearch && matchesCategory;
  });
});

const setActiveCategory = (category) => {
  activeCategory.value = category;
};

//工作台链接
const openwork1 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/runway" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork2 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/luma" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork3 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/kling" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork4 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/RunwayGen3" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork5 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/Wenshengvideo" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork6 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/oldphotos" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork7 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/funnyvideos" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork8 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/Actionvideos" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openwork9 = () => {
  if (isLoggedIn.value) {
    router.push({ path: "/Videogenerates" }); // 跳转到指定路径
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
const openVIP = () => {
  if (isLoggedIn.value) {
    dialogVisible.value = true
  } else {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    ElMessage({
      message: "请先登录！",
      type: "error",
    });
  }
};
</script>
<style scoped>
@import "./css/variables.css";

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* # Sidebar */
.sticky-sidebar {
  /* background: linear-gradient(rgba(0, 163, 255, 0.05) 0%, rgba(0, 163, 255, 0.02) 100%); */
  width: 260px;
  position: fixed;
  height: 100%;
  z-index: 9;
  /* backdrop-filter: blur(15.0014px); */
  border: 0.75px solid rgba(252, 252, 252, 0.05);
  -webkit-backdrop-filter: blur(15.0014px);
  backdrop-filter: blur(15.0014px);
  background: linear-gradient(
    rgba(0, 163, 255, 0.06) 100%,
    rgba(0, 163, 255, 0.5) 0%
  );
}

.sticky-sidebar:hover {
  z-index: 99;
}

.left {
  margin-top: 5px;
}

.page-content-wrapper {
  width: calc(100% - 265px);
  z-index: 10;
  margin-left: auto;
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;

  height: auto;
  justify-content: flex-start;
  position: relative;
}

.content-left {
  width: 100%;
}

.nav-item {
  padding-left: 10px;
  /* 添加左内边距，与图标间距一致 */
  height: 46px;
  background-color: #282c34;
  margin: 10px 0;
  line-height: 46px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.nav-item span {
  font-size: 16px;
}

.nav-home {
  background-color: #1f2329;
}

.nav-item:hover {
  background-color: #3a3e45;
}

.product-highlights-container {
  margin: 0;
  /* padding-left: 20px; */
  width: 100%;
  height: 230px;
}

.highlight-header {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
}

.highlight-header h2 {
  font-size: 18px;
  color: #ffffff;
}

.view-all-button {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  /* 边框颜色 */
  padding: 5px 10px;
  /* 内边距 */
  border-radius: 5px;
  /* 圆角 */
  font-size: 12px;
  /* 字体大小 */
  cursor: pointer;
  color: #fff;
  /* 字体颜色 */
  margin-left: 10px;
  background-color: transparent;
}

.view-all-button::after {
  content: "▼";
  /* 添加小箭头 */
  font-size: 10px;
  /* 小箭头大小 */
  margin-left: 5px;
  /* 图标与文本之间的距离 */
}

.right-header {
  width: 100%;
  height: 60px;
  margin-right: 10px;
}


.toolbox {
  width: 550px;
  height: 100%;
  display: flex;
  float: right;
  align-items: center;
  margin-right: 60px;
}

.ipone {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ipone svg{
width: 25px;
height: 25px;
}

.join-group {
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-group a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark-site {
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.invite-users {
  width: 134px;
  height: 24px;
  border-radius: 4px;
  background: -webkit-linear-gradient(90deg, #d357fe, #be38f3, #7a219e);
  background: linear-gradient(90deg, #d357fe, #be38f3, #7a219e);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.invite {
  width: 100%;
  margin: 0 auto;
  background-color: #1a202c;
  color: #e2e8f0;
  padding: 2rem;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #a78bfa;
}

.description {
  margin-bottom: 1rem;
  margin-top: 40px;
}

.benefits-list {
  list-style-type: decimal;
  list-style-position: inside;
  margin-bottom: 1.5rem;
}

.benefits-list li {
  margin-bottom: 0.5rem;
}

.highlight {
  color: #a78bfa;
}

.cashout {
  color: #fbbf24;
  cursor: pointer;
}

.cashout:hover {
  text-decoration: underline;
}

.referral-input {
  display: flex;
  margin-bottom: 1.5rem;
  background-color: #2d3748;
  border-radius: 0.375rem;
  overflow: hidden;
}

.referral-input p {
  flex-grow: 1;
  background-color: #4a5568;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
}

.referral-input button {
  background-color: #4a5568;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.referral-input button:hover {
  background-color: #2d3748;
}

.user-table {
  background-color: #2d3748;
  border-radius: 0.375rem;
  padding: 1rem;
  height: 463px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.registered-users {
  color: #60a5fa;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.75rem 1rem;
  text-align: left;
}

th {
  border-bottom: 1px solid #4a5568;
}

.no-data {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* 添加这一行来垂直居中 */
  padding: 2rem 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  background-color: #4a5568;
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.pagination button.active {
  background-color: #60a5fa;
}

.pagination select {
  background-color: #4a5568;
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
}

.login {
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 50%;
}

.logged-in {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.not-login {
  font-size: 14px;
  width: 100px;
  height: 36px;
  border-radius: 20px;
  line-height: 36px;
  color: #ffffff;
  background: linear-gradient(90deg, #4a148c 0%, #8e24aa 100%);
  text-align: center;
  margin-left: 20px;
}

.not-login:hover {
  background: linear-gradient(90deg, #6a1b9a, #ab47bc);
  transform: scale(1.05);
  /* 悬停时轻微放大 */
}

.custom-login-dialog {
  width: 700px;
  max-width: 700px;
  /* 确保对话框最大宽度 */
  height: 440px;
  max-height: 440px;
  /* 确保对话框最大高度 */
  margin: 0;
  padding: 0;
  background-color: #000;
}

.suspend {
  width: 340px;
  float: right;
  background: #bdc3c7;
  background: -webkit-linear-gradient(
    to top,
    rgba(189, 199, 193, 0.6),
    rgba(32, 43, 55, 0.9)
  );
  background: linear-gradient(
    to right top,
    rgba(231, 244, 237, 0.6),
    rgba(32, 43, 55, 0.8),
    rgba(32, 43, 55, 0.9)
  );
  position: fixed;
  top: 50px;
  /* 根据需要调整 */
  right: 20px;
  /* 根据需要调整 */
  z-index: 9999;
  border-radius: 10px;
}

.suspend-head {
  display: flex;
  align-items: center;
  padding: 10px;
}

.suspend-head img {
  width: 50px;
  /* 图片宽度 */
  height: 50px;
  /* 图片高度 */
  border-radius: 50%;
  /* 圆形图片 */
  margin-right: 10px;
  /* 图片与文字间距 */
}

.suspend-head span {
  color: #e8e8e8;
  /* 字体颜色 */
  font-size: 12px;
  /* 字体大小 */
}

.suspend-head a {
  /* 个人资料链接样式 */
  color: #cccccc;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  text-decoration: none;
}

.suspend-line {
  width: 90%;
  height: 1px;
  background-color: #cccccc;
  margin: 0 auto;
  margin-top: 10px;
}

.integral {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #cccccc;
  /* 字体颜色 */
  font-size: 14px;
  /* 字体大小 */
}

.integral div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.integral div span:first-child {
  display: block;
  font-size: 14px;
  /* 积分数字的字体大小 */
  color: #ffffff;
}

.integral div span:last-child {
  font-size: 14px;
  /* 描述文字的字体大小 */
  color: #cccccc;
}

.open-vip {
  width: 90%;
  margin: 0 auto;
}

.vip-content {
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 156, 164, 0.1),
    rgba(39, 199, 173, 0.1),
    rgba(117, 239, 156, 0.1)
  );
  background: linear-gradient(
    90deg,
    rgba(44, 156, 164, 0.1),
    rgba(39, 199, 173, 0.1),
    rgba(117, 239, 156, 0.1)
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vip-text {
  color: #fff;
  font-size: 13px;
  margin-left: 10px;
  text-align: left;
}

.vip-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.box-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.button-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;
  color: #cccccc;
  font-size: 14px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  fill: #cccccc;
}

.el-message-box__wrapper {
  width: 100% !important;
  height: 100% !important;
}

.custom-login-dialog .login-dialog {
  display: flex;
  width: 100%;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  padding: 0;
  /* 取消内边距 */
  margin: 0;
  /* 取消外边距 */
}

/* 使用 ::v-deep 选择器确保修改到 el-dialog 内部样式 */
::v-deep(.activation-code-dialog) {
  width: 320px;
  height: 200px;
  border-radius: 10px;
  top: 200px;
  background-image: linear-gradient(
      252.37deg,
      #1b262b 0.55%,
      #171b21,
      #191d23 90.08%
    ),
    linear-gradient(
      68.56deg,
      #1e2930 29.44%,
      #1d1d1d 59.6%,
      #262a2f 82.91%,
      #2e4141 101.21%
    ),
    url("@/assets/home/glowing.png") !important;
  border-color: #1e3139 !important;
  padding: 24px !important;

  background-size: cover;
  /* 背景图片会根据容器大小自适应 */
  background-position: center;
  /* 背景图片居中 */
}

::v-deep(.activation-code-dialog .el-dialog__title) {
  color: #fff;
}

/* 修改 el-dialog 标题部分样式 */
::v-deep(.activation-code-dialog .el-dialog__header) {
  border-bottom: none;
  /* 移除标题区域的底部边框 */
  border-radius: 12px 12px 0 0;
  /* 圆角，只对顶部生效 */
}

/* 修改激活按钮样式 */
::v-deep(.dialog-actions .el-button) {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  color: #fff;
  /* 按钮文字颜色 */
  border-radius: 8px;
  /* 按钮圆角 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* 按钮阴影 */
}

::v-deep(.dialog-actions .el-button:hover) {
  background-color: #005bb5;
  /* 按钮悬停时的背景颜色 */
}

.left-section {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0;
  margin-top: -30px;
  margin-left: -20px;
}

.left-section img {
  width: 100%;
  margin-bottom: 20px;
}

.left-section h2 {
  font-size: 20px;
  margin-top: 10px;
  color: #28fb55;
}

.right-section {
  max-width: 400px;
  height: 440px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  float: right;
  margin-top: -460px;
}

.right-section h3 {
  align-self: flex-start;
  /* 靠左对齐标题 */
  color: #fff;
  font-size: 22px;
}

.right-section p {
  font-size: 14px;
  margin-top: 20px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  /* 左对齐 */
  margin-bottom: 20px;
  margin-top: 40px;
}

.tabs span {
  cursor: pointer;
  padding-bottom: 10px;
  color: #f9f9f9;
}

.tabs .active {
  color: #28fb55;
  margin-top: 20px;
  margin: 0 auto;
  font-size: 16px;
  color: #c5c7d5;
}

.input-group {
  width: 360px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input {
  flex-grow: 1;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #333;
  color: #fff;
}

.input-group button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: #5865f2;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.terms {
  font-size: 12px;
  color: #a1a1aa;
  padding-left: 10px;
}

.terms a {
  color: #28fb55;
}

.submit-btn {
  width: 90%;
  padding: 12px;
  background-color: #5865f2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 60px;
  margin-left: 10px;
}

.highlight-cards-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  overflow: hidden;
}

.highlight-cards {
  display: flex;
  margin-top: 15px;
  justify-content: flex;
  overflow: hidden;
  width: 100%;
  height: 220px;
}

.highlight-card {
  flex: 0 0 calc((100% - 60px) / 4);
  height: 140px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: transform 1s ease, width 1s ease, height 1s ease, margin 1s ease;
  /* 增加 margin 的过渡 */
}

.highlight-card:hover {
  transform: scale(1.05);
  z-index: 10;
}

.highlight-card.shrink {
  transform: scale(0.87);
  /* 只缩小未悬停的卡片 */
}

.highlight-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease, pointer-events 1s ease;
  border-radius: 10px;
}

.highlight-card:hover .highlight-video {
  opacity: 1;
  pointer-events: auto;
}

.x-button-prev,
.x-button-next {
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
}

.x-button-prev {
  left: 10px;
}

.x-button-next {
  right: 10px;
}

.hot-recommendation {
  width: 100%;
  height: 100%;
}

.hot-header {
  position: relative;
  height: 200px;
  width: 98%;
  margin: 20px auto;
  border-radius: 10px;
  text-align: center;
  line-height: 200px;
}

.hot-header h2 {
  font-size: 35px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 25%;
}

.ButtonSwitcher-filter {
  display: flex;
  height: 34px;
  background-color: rgb(238, 238, 250);
  margin-left: 20px;
}

.button-item {
  color: #000;
}

.hot-cards {
  display: flex;
  margin-left: 20px;
}

.horizontal {
  width: 327px;
  height: 344px;
  margin-right: 20px;
}

.horizontal-item {
  width: 327px;
  height: 184px;
  margin-bottom: 20px;
}

.horizontal-item video {
  width: 100%;
  height: 100%;
  border: #005bb5 solid 1px;
}

.vertical {
  width: 327px;
  height: 388px;
  margin-right: 20px;
}

.vertical video {
  width: 100%;
  height: 100%;
  border: #00aaff solid 1px;
}

/* 放大视频 */
.pop-up {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
}

.pop-left {
  width: 550px;
  height: 520px;
  background-color: rgb(28, 38, 43);
  display: flex;
  /* 添加这一行使 .pop-left 成为 flex 容器 */
  align-items: center;
  /* 使子元素垂直居中 */
}

.studio-info {
  width: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

.pop-right {
  width: calc(100% - 550px);
  height: 520px;
  background-color: rgb(25, 31, 37);
  float: right;
}

.creation-details {
  font-size: 22px;
  color: #fff;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.call-word {
  width: 100%;
  height: 280px;
  border: #1a7b7f dashed 1px;
  margin-top: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 25px;
  text-indent: 2em;
  padding-left: 10px;
}

.cta-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  float: right;
  margin-right: 10px;
}

.main-button {
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.main-button:hover {
  background-color: #5b4bc4;
}

.icon-buttons {
  display: flex;
  gap: 10px;
}

.icon-button {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.icon-button:hover {
  background-color: #f5f5f5;
}

.icon-button svg {
  width: 20px;
  height: 20px;
  color: #6c5ce7;
  transition: fill 0.3s ease;
}

.container {
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: flex-end;
  /* 右对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  margin-right: 0px;
  /* 右侧边距，可以根据需要调整 */
}

.favicon,
.share {
  margin: 0 10px;
  /* 设置盒子间的间隔 */
}

.share .icon {
  width: 30px;
  height: 28px;
}

.favicon {
  height: 30px;
  /* 调整高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  --c: #ff6b81;
}

.favicon svg {
  width: 30px;
  /* 调整 SVG 宽度 */
  position: relative;
}

.favicon #heart {
  fill: #eee;
  stroke: var(--c);
  stroke-width: 15px;
  /* 缩小线条宽度 */
  stroke-dasharray: 1300;
  /* 缩短虚线的长度 */
  stroke-dashoffset: 1300;
  /* 相应调整虚线位移 */
  stroke-linecap: round;
}

.background-video {
  width: 100%;
  /* 每个视频占据50%的宽度 */
  height: 100%;
  /* 高度为260px */
  object-fit: cover;
  /* 确保视频覆盖整个元素 */
  z-index: -1;
  /* 保证视频在最底层 */
}

.favicon span {
  display: block;
  width: 16px;
  /* 调整小圆点的大小 */
  height: 16px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: 80%;
  transform: translate(-50%, -50%) scale(0);
  box-shadow: 0 -80px 0 var(--c), 0 80px 0 var(--c), -80px 0 0 var(--c),
    80px 0 0 var(--c), -60px -60px 0 var(--c), 60px -60px 0 var(--c),
    60px 60px 0 var(--c), -60px 60px 0 var(--c);
}

.favicon #checkbox:checked + svg #heart {
  animation: drawHeart 0.6s linear forwards;
  /* 缩短动画时长 */
}

.favicon #checkbox:checked ~ span {
  animation: blink 0.4s ease-in-out forwards;
  animation-delay: 0.6s;
}

.favicon #checkbox:checked + svg {
  animation: beat 0.8s linear forwards;
}

@keyframes drawHeart {
  0% {
    stroke-dashoffset: 1300;
  }

  80% {
    fill: #eee;
    stroke-dashoffset: 0;
  }

  100% {
    fill: var(--c);
    stroke-dashoffset: 0;
  }
}

@keyframes blink {
  0% {
    transform: translate(-50%, -50%) scale(0.3);
    /* 调整缩放大小 */
    opacity: 0.8;
  }

  50% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1);
  }

  80% {
    transform: scale(1.1);
    /* 调整心脏缩放效果 */
  }

  100% {
    transform: scale(1);
  }
}

.image-grid {
  column-count: 4;
  /* 指定多列布局的列数 */
  column-gap: 1rem;
  /* 列之间的间隙 */
}

.image-container {
  break-inside: avoid;
  /* 防止图片在中间断开 */
  margin-bottom: 1rem;
  /* 图片之间的垂直间距 */
  position: relative;
  background-color: #000000;
  border-radius: 12px;
  overflow: hidden;
  transition: background-color 0.4s;
}

.video-container {
  width: 320px;
  position: relative;
  max-width: 36rem;
  overflow: hidden;
  background-color: #000000;
  border-radius: 12px;
  overflow: hidden;
  float: left;
  margin-left: 2%;
  margin-bottom: 20px;
  height: 250px;
  transition: background-color 0.4s;
}

.video-container:hover .bottom-controls,
.image-container:hover .bottom-controls1 {
  background-color: #1a1a1a85;
}

.bottom-controls {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  left: 0;
  right: 0;
  bottom: -10px;
  transform: translateY(10px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bottom-controls1 {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  left: 0;
  right: 0;
  bottom: 0px;
  transform: translateY(10px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bottom-controls1 .copy {
  width: 80%;
  color: #fff;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.video-container:hover .bottom-controls,
.image-container:hover .bottom-controls1 {
  opacity: 1;
  transform: translateY(0);
}

.video-wrapper {
}

.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
}

.image-container img {
  width: 100%;
  /* 图片宽度为容器宽度 */
  display: block;
  /* 去除默认的基线对齐 */
}

@media (max-width: 768px) {
  .image-grid {
    column-count: 2;
    /* 在窄屏幕上减少列数 */
  }
}

@media (max-width: 480px) {
  .image-grid {
    column-count: 1;
    /* 在手机上只有一列 */
  }
}

.membership-dialog {
  background-color: #1f2937;
  color: white;
}

.membership-dialog :deep(.el-dialog__title) {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.membership-dialog :deep(.el-dialog__headerbtn .el-dialog__close) {
  color: white;
}

.subtitle {
  margin-bottom: 1rem;
}

.subscription-options {
  margin-bottom: 2rem;
}

.membership-tiers {
  margin-bottom: 2rem;
}

.tier-card {
  height: 100%;
  background-color: #374151;
  color: white;
}

.tier-card :deep(.el-card__header) {
  background-color: #4b5563;
  padding: 1rem;
}

.tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tier-header h3 {
  margin: 0;
  font-size: 1.25rem;
}

.discount-tag {
  font-size: 0.75rem;
}

.tier-price {
  margin-bottom: 1rem;
}

.current-price {
  font-size: 1.875rem;
  font-weight: bold;
}

.original-price {
  font-size: 16px;
  color: #9ca3af;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.price-period {
  font-size: 0.875rem;
  color: #9ca3af;
}

.tier-description {
  font-size: 0.875rem;
  color: #9ca3af;
  margin-bottom: 1rem;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.feature-list li {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.feature-list li .el-icon {
  margin-right: 0.5rem;
  color: #22c55e;
}

.subscribe-button {
  width: 100%;
}



.footer-text {
  font-size: 0.875rem;
  color: #9ca3af;
  text-align: center;
}

.image-grid img {
  border-radius: 10px;
}

.big-box {
  width: 97%;
  margin: 0 auto;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
}

.subtitle {
  margin-left: 20px;
}

.subscription-options {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subscription-options :deep(.el-radio-button__inner) {
  border: none;
  background-color: rgb(32, 44, 58);
  /* 未选中时的背景颜色 */
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  height: auto;
  line-height: 1.5;
  transition: all 0.3s ease;
  /* 添加过渡效果 */
}

.subscription-options
  ::v-deep
  .el-radio-button.is-active
  .el-radio-button__inner {
  background: linear-gradient(90deg, #00ffcc, #0099ff);
  /* 选中时的渐变背景 */
  color: #ffffff !important;
  border-color: rgba(0, 255, 204, 0.5);
  /* 外发光的边框颜色 */
  box-shadow: 0 0 8px rgba(0, 255, 204, 0.7);
  /* 添加外发光效果 */
}

.subscription-options
  :deep(.el-radio-button:first-child .el-radio-button__inner) {
  border-radius: 10px 0 0 10px;
}

.subscription-options
  :deep(.el-radio-button:last-child .el-radio-button__inner) {
  border-radius: 0 10px 10px 0;
}

.subscription-options
  :deep(.el-radio-button:not(:first-child) .el-radio-button__inner) {
  margin-left: 1px;
}

.subscription-options
  :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
  z-index: 1;
  position: relative;
}

.membership-tiers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 20px;
  /* 设置左右边距 */
}

.tier-card {
  position: relative;
  height: 540px;
  background-color: #263042;
  color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: none;
  width: 100%;
  /* 让卡片宽度自动根据列宽度调整 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px transparent;
  /* 默认透明边框，确保不会对布局产生影响 */
  transition: border-color 0.3s ease;
}

.tier-card-1 {
  background-image: url("../assets/home/tier-card1.png");
}

.tier-card-2 {
  background-image: url("../assets/home/tier-card2.png");
}

.tier-card-3 {
  background-image: url("../assets/home/tier-card3.png");
}

.tier-card-4 {
  background-image: url("../assets/home/tier-card4.png");
}

.tier-card-1:hover {
  border-color: #999;
}

.tier-card-2:hover {
  border-color: #ffc75e;
}

.tier-card-3:hover {
  border-color: #4169e1;
}

.tier-card-4:hover {
  border-color: #9370db;
}

.tier-card :deep(.el-card__header) {
  background-color: transparent;
  padding: 1rem;
  border-bottom: none;
}

.tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.tier-header h3 {
  margin-top: 20px;
  font-size: 1.25rem;
}

.discount-tag {
  position: absolute;
  top: -16px;
  right: -16px;
  font-size: 0.75rem;
  background-color: #ff7f50;
  border-radius: 0 4px 0 0;
  color: #ffffff;
}

.tier-price {
  margin-bottom: 10px;
}

.current-price {
  font-size: 1.875rem;
  font-weight: bold;
  vertical-align: middle;
}

.price-period {
  font-size: 12px;
  color: #9ca3af;
  vertical-align: middle;
  margin-bottom: 20px;
}

.tier-description {
  font-size: 12px;
  color: #9ca3af;
  margin-top: 20px;
  padding: 0;
}

.discount-percentage {
  color: #00ffff;
  font-weight: bold;
}

/* .credit-info {
    background: linear-gradient(90deg, #00ffcc, #0099ff);
    border-radius: 20px;
    padding: 0.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
  } */
.credit-info {
  margin-top: 20px;
  font-size: 14px;
}

.credit-amount {
  font-size: 24px;
  font-weight: bold;
  color: #52c41a;
  /* Bright green color */
  margin: 0 4px;
}

.credit-amount {
  font-size: 12px;
  font-weight: bold;
}

.credit-description {
  font-size: 12px;
  color: #9ca3af;
  margin-top: 10px;
}

.vip-list {
  width: 100%;
  height: 1px;
  background-color: rgb(17, 24, 29);
  margin: 0 auto;
  margin-top: 20px;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.feature-list li {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-top: 10px;
  color: #ffffff;
}

.feature-list li .el-icon {
  margin-right: 0.5rem;
  color: #22c55e;
}

.subscribe-button {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 48px;
  background: linear-gradient(to right, #27c9bb, #75a0ef);
  border-radius: 40px;
  padding: 10px 0;
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
}

::v-deep .current-button {
  background: #333 !important;
  border-color: #333 !important;
  color: white !important; /* 如果背景色是亮色，建议修改文字颜色 */
}

.first-purchase-note {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.75rem;
  color: #9ca3af;
}

.left-bottom {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  color: #e0e0e0;
  font-size: 14px;
  cursor: pointer;
  gap: 10px;
}

.bottom-line {
  width: 1px;
  height: 14px;
  background-color: rgb(100, 104, 119);
}

.left-bottom .upgrade-announcement,
.left-bottom .official-platform {
  margin: 0;
}

.separator {
  margin: 0 10px;
}

/* .official-platform {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    color: #e0e0e0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    cursor: pointer;
  } */
.official-info {
  position: absolute;
  left: 50%;
  bottom: 60px;
  width: 320px;
  height: 240px;
  background-color: rgb(43, 52, 64);
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.official-info h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.official-info p {
  font-size: 12px;
  margin-bottom: 12px;
  color: rgb(100, 104, 119);
}

.official-links {
  width: 100%;
  border: rgb(100, 104, 119) solid 1px;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.official-links p {
  font-size: 12px;
  margin-bottom: 20px;
}

.official-links .label {
  color: #a0a0a0;
}

.official-links .value {
  color: #ffffff;
}

.qr-codes {
  width: 100%;
  border: rgb(100, 104, 119) solid 1px;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.qr-code {
  text-align: center;
}

/* .qr-code img {
    width: 80px;
    height: 80px;
    margin-bottom: 4px;
  } */

.qr-code p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 10px;
}

.video-card {
  width: 500px;
  height: 450px;
  border-radius: 20px;
}

.video-background {
  width: 100%;
  height: 280px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0 0;
}

.promo-card {
  width: 100%;
  height: 162px;
  background: rgba(32, 43, 55, 0.9);
  /* background: -webkit-linear-gradient(
      to top,
      rgba(189, 199, 193, 0.6),
      rgba(32, 43, 55, 0.9)
    );
    background: linear-gradient(
      to right top,
      rgba(231, 244, 237, 0.6),
      rgba(32, 43, 55, 0.8),
      rgba(32, 43, 55, 0.9)
    ); */
  font-family: Arial, sans-serif;
  padding: 20px;
  color: #fff;
  border-radius: 0 0 20px 20px;
}

.card-content {
  display: flex;
  justify-content: space-between;
}

.promo-card .title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
}

.promo-card .star {
  color: #ffd700;
  margin-left: 5px;
}

.promo-card .studio-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.promo-card .logo {
  width: 24px;
  height: 24px;
  background-color: #6c5ce7;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.promo-line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.bottom-content {
  font-size: 12px;
  color: #ccc;
  line-height: 2;
  margin-top: 10px;
}

.cta-button {
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #5b4bc4;
}

.arrow {
  margin-left: 5px;
}

.search-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* background-color: #1e1e1e; */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
}

.search-bar {
  position: relative;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.search-input {
  width: 90%;
  padding: 10px 20px 10px 40px;
  font-size: 12px;
  background-color: #2c2c2c;
  color: #ffffff;
  border: 2px solid #fff;
  border-radius: 25px;
  outline: none;
}

.search-input::placeholder {
  color: #888888;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #888888;
}

.categories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.category-button {
  background-color: transparent;
  color: #888888;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
}

.category-button:hover,
.category-button.active {
  background-color: #3a3a3a;
  color: #ffffff;
}

.hot-icon {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 30px;
  margin-top: 3px;
}

.no-more-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  margin-top: 150px;
}

.icon-container {
  margin-bottom: 10px;
}

.icon1 {
  width: 250px;
  height: 150px;
  color: #a0aec0;
}

.no-more-data-text {
  font-size: 18px;
  color: #4a5568;
  margin: 0;
}

.vip-title {
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(to right, #fff, #4dd0e1, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  padding: 20px;
  background-color: #1a202c;
  /* Dark background color */
  margin-top: 20px;
}

.sidebar {
  width: 240px;
  height: 100vh;
  /* background-color: #040303; */
  color: white;
  padding: 16px;
}

.logo {
  margin-bottom: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}
.menu {
  height: 70vh;
  overflow: scroll;
}
.menu::-webkit-scrollbar {
  display: none;
}

.sidebar ul h4 {
  color: #ccc;
  margin: 8px;
  font-size: 14px;
}

.menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 6px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;
}

.menu-item::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  /* background: linear-gradient(45deg, #ff00ff, #00ffff); */
  z-index: -1;
  filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.menu-item:hover::before {
  opacity: 1;
}

.menu-item:hover {
  background-color: rgba(42, 42, 58, 0.8);
}

.menu-item.active {
  background: linear-gradient(90deg, #4a148c 0%, #8d24aa90 100%);
}

.menu-item-content {
  display: flex;
  align-items: center;
}

.menu-item-content svg {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.menu-item-content span {
  font-size: 14px;
}

.menu-item-wrapper {
  position: relative;
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #2a2a3a;
  border-radius: 8px;
  padding: 8px;
  min-width: 150px;
  z-index: 10;
}

.submenu-item {
  padding: 8px;
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: #4a148c;
}

.el-divider--horizontal {
  border-top: 1px var(--el-border-color) var(--el-border-style);
  display: block;
  height: 1px;
  margin: 6px 0;
  width: 100%;
}

.glow-circle {
  position: fixed;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, rgba(138, 43, 226, 0.3), transparent 60%);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
}
</style>