<template>
  <!-- 头部 -->
  <div class="box-header">
    <a href="/">
      <div class="back">
        <img src="../assets/syhbs/back.png" alt="" />
      </div>
    </a>
    <div class="header-line"></div>
    <div class="logo">
      <img src="../assets/home/LOGO.png" alt="" />
    </div>
    <!-- 登录 -->
    <div class="login" plain @click="handleLoginClick" @mouseover="showSuspend = true" @mouseleave="handleMouseLeave">
      <template v-if="isLoggedIn">
        <img src="../assets/home/login.jpg" alt="已登录" style="
                  float: right;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  margin-right: -40px;
                " />
      </template>
      <template v-else>
        <div class="not-login">注册/登录</div>
      </template>

      <!-- 悬停提示盒子 -->
      <div v-if="showSuspend && isLoggedIn" class="suspend" @mouseover="showSuspend = true"
        @mouseleave="showSuspend = false">
        <template v-if="isLoggedIn">
          <div class="suspend-head">
            <img src="../assets/home/login.jpg" alt="" />
            <span>用户名:{{ username }}</span>
            <a href="#">个人资料&nbsp;></a>
          </div>
          <div class="suspend-line"></div>
          <div class="integral">
            <div>
              <span>{{ userid }}</span>
              <span>用户ID</span>
            </div>
            <div>
              <span>{{ drawNum }}</span>
              <span>积分详情&nbsp;></span>
            </div>

          </div>
          <div class="open-vip">
            <div class="vip-content">
              <div class="vip-text">
                <div>升级更高等级会员，享折上折!</div>
              </div>
              <button class="vip-button" @click="dialogVisible = true">
                开通
              </button>
            </div>
          </div>
          <div class="box-button">
            <div class="button-item">
              <svg t="1726811265160" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="3361" width="200" height="200">
                <path
                  d="M623.3302914844444 203.89989148444442H97.74775296000001s-88.02860259555555 0-88.02860259555555 85.43952554666666v432.37578296888887c0 85.43952554666666 88.02860259555555 85.43952554666666 88.02860259555555 85.43952554666666h525.5825385244444s88.02860259555555 0 88.02860259555555-85.43952554666666V291.92849408000006c0-88.02860259555555-88.02860259555555-88.02860259555555-88.02860259555555-88.02860259555555zM983.2119307377778 247.9141922133333c-7.767230008888888-2.5890770488888886-15.534458879999999-2.5890770488888886-20.71261184 2.5890770488888886l-173.46812928 134.63198037333333c-5.17815296 5.17815296-7.767230008888888 10.35630592-7.7672288711111115 15.534458879999999v214.89335296000002c0 5.17815296 2.5890770488888886 12.945382968888888 7.7672288711111115 15.534460017777777l173.46812928 134.63197923555558c2.5890770488888886 2.5890770488888886 7.767230008888888 5.17815296 12.945381831111112 5.178154097777777 2.5890770488888886 0 5.17815296 0 10.356307057777778-2.5890770488888886 7.767230008888888-2.5890770488888886 10.35630592-10.35630592 10.35630592-18.123535928888888V266.03772814222225c0-7.767230008888888-5.17815296-15.534458879999999-12.945382968888888-18.123535928888888z"
                  fill="currentColor" p-id="3362"></path>
              </svg>
              <a href="/myvideos" style="color: #cccccc;text-decoration: none;">我的视频</a>
            </div>
            <div class="button-item" @click="showActivationDialog = true">
              <svg t="1726811329693" class="icon" viewBox="0 0 1152 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="9183" width="200" height="200">
                <path
                  d="M979.586776 1023.936004H172.405225A172.981189 172.981189 0 0 1 0 850.634835V732.754203c0-42.109368 25.150428-80.506968 63.996-97.913881a134.711581 134.711581 0 0 0 0-245.680644C25.214424 371.816761 0 333.419161 0 291.373789V173.301169A172.981189 172.981189 0 0 1 172.405225 0h807.117555A172.981189 172.981189 0 0 1 1151.928004 173.301169v117.816636c0 42.173364-25.214424 80.570964-64.123992 97.913881a134.775577 134.775577 0 0 0 0 245.872632c39.03756 17.342916 64.123992 55.804512 64.123992 97.913881v117.816636A172.981189 172.981189 0 0 1 979.586776 1023.936004zM172.405225 79.995c-51.1968 0-92.7942 41.853384-92.794201 93.306169V291.181801c0 10.687332 6.3996 20.15874 16.766952 24.830448a214.898569 214.898569 0 1 1 0 391.911506 27.262296 27.262296 0 0 0-16.830948 24.830448v117.880632c0 51.452784 41.5974 93.306168 92.794201 93.306169h807.181551c51.1968 0 92.7942-41.853384 92.7942-93.306169v-117.816636c0-10.687332-6.3996-20.222736-16.766952-24.830448A214.706581 214.706581 0 0 1 928.581964 511.968002c0-84.730704 49.788888-161.653897 126.904068-196.019749a27.262296 27.262296 0 0 0 16.830948-24.830448V173.301169c0-51.452784-41.5974-93.306168-92.7942-93.306169H172.341229z"
                  fill="currentColor" p-id="9184"></path>
                <path
                  d="M737.169927 411.494282H422.501594a39.933504 39.933504 0 0 1-39.805513-39.997501c0-22.014624 17.854884-39.933504 39.805513-39.933504h314.668333a39.933504 39.933504 0 1 1 0 79.995001z m0 270.767077H422.501594a39.933504 39.933504 0 0 1-39.805513-39.9975c0-22.07862 17.854884-39.9975 39.805513-39.997501h314.668333c21.950628 0 39.741516 17.91888 39.741516 39.997501a39.933504 39.933504 0 0 1-39.741516 39.9975z"
                  fill="currentColor" p-id="9185"></path>
              </svg>
              <span>激活码兑换</span>
            </div>

            <div class="button-item">
              <svg t="1727056941151" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="18151" width="200" height="200">
                <path
                  d="M946.688 447.488c-19.968-14.336-46.08-17.408-68.608-8.704l-8.192 3.072V166.4c0.512-44.032-34.816-80.896-78.848-81.408H238.592h-0.512c-44.032 1.024-79.36 37.888-78.336 81.408V430.08l-7.68-3.584C128.512 417.28 102.4 420.352 81.92 435.2c-18.944 13.824-30.208 36.352-30.208 59.904v363.008c0 19.456 7.68 37.888 21.504 52.224s32.256 21.504 51.712 21.504h779.264c19.456 0 37.888-7.68 51.712-21.504 13.824-13.824 21.504-32.256 21.504-51.712V507.392c0-23.552-11.264-46.08-30.72-59.904z m-732.16-281.6c0-13.824 10.752-25.6 24.576-26.112h550.912c13.824 0.512 25.088 11.776 24.576 26.112v297.472l-300.032 116.736-300.032-126.976V165.888z m708.096 692.224c0 5.12-2.048 9.728-5.632 13.312-3.584 3.584-8.192 5.632-12.8 5.632H124.928c-5.12 0-9.728-2.048-13.312-5.632-3.584-3.584-5.632-8.192-5.632-13.312v-363.52c0-6.144 2.56-11.776 7.68-14.848 3.072-2.048 6.656-3.584 10.24-3.584 2.048 0 4.096 0.512 6.144 1.024l373.76 157.696c6.656 2.56 13.824 3.072 20.48 0.512l373.76-145.408c5.632-2.048 11.776-1.536 16.384 2.048 5.12 3.584 8.192 9.216 8.192 15.36v350.72z"
                  fill="currentColor" p-id="18152"></path>
                <path
                  d="M540.672 475.136l92.16-90.112c2.048-1.024 3.584-2.56 5.12-4.608 22.528-24.064 31.232-57.344 24.576-88.576-7.168-35.328-34.816-64-70.144-72.704-27.136-6.656-54.784-0.512-76.8 15.36-21.504-15.872-49.664-22.016-76.8-15.36-34.816 8.704-62.464 37.376-69.632 72.192-6.144 31.232 2.048 64 23.552 87.552l0.512 0.512c1.024 1.536 2.048 2.56 3.584 4.096l93.184 91.136 0.512 0.512c7.168 7.168 16.384 10.752 25.6 10.752s17.92-3.584 24.576-10.752zM436.736 346.624c-1.024-1.024-1.536-2.048-2.56-3.072-10.24-10.752-13.824-25.6-10.752-39.936v-0.512c3.072-14.848 14.848-27.136 29.184-30.72 15.872-4.096 31.744 2.56 40.96 16.384a27.74528 27.74528 0 0 0 46.08 0c8.704-13.312 25.088-19.968 40.448-16.384 14.848 3.584 26.112 15.872 29.696 31.232 3.072 12.8 0 26.112-8.192 36.864l-1.536 1.536c-1.536 1.024-2.56 2.56-4.096 3.584l-79.872 78.336-79.36-77.312z"
                  fill="currentColor" p-id="18153"></path>
              </svg>
              <span>邀请用户</span>
            </div>
            <div class="button-item" :plain="true" @click="handleLogout">
              <svg t="1726811042569" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="1444" width="200" height="200">
                <path
                  d="M952.532295 495.269967 767.686357 310.423005c-9.060353-9.060353-23.75196-9.060353-32.81743 0l-37.363979 37.362956c-9.060353 9.060353-9.060353 23.757077 0 32.81743l81.538061 81.538061L407.384337 462.141452c-12.812817 0-23.199375 10.386558-23.199375 23.199375l0 52.845579c0 12.815887 10.386558 23.204491 23.199375 23.204491l371.479593 0-81.538061 81.538061c-9.060353 9.060353-9.060353 23.757077 0 32.81743l37.368072 37.363979c9.060353 9.05933 23.75503 9.05933 32.815383 0l147.653875-147.653875c0-0.005117 0.005117-0.005117 0.005117-0.005117l37.368072-37.368072C961.592648 519.020904 961.592648 504.33032 952.532295 495.269967L952.532295 495.269967zM634.083499 64.754816l-499.803213 0c-38.441521 0-69.608358 31.166837-69.608358 69.608358l0 754.806002c0 38.446637 31.166837 69.608358 69.608358 69.608358l499.803213 0c38.441521 0 69.608358-31.16172 69.608358-69.608358l0-97.937566c0-12.811794-10.386558-23.204491-23.204491-23.204491l-50.29243 0c-12.812817 0-23.205515 10.392698-23.205515 23.204491l0 37.257555c0 34.328853 0 34.328853-34.791387 34.328853L195.199751 862.818017c-34.801621 0-34.801621 0.00614-34.801621-34.806737L160.39813 194.712657c0-34.900881-0.074701-34.802644 34.801621-34.802644l376.99726 0c34.798551 0 34.791387 0.285502 34.791387 34.329876l0 38.353516c0 12.815887 10.392698 23.204491 23.205515 23.204491l50.29243 0c12.817933 0 23.204491-10.388605 23.204491-23.204491L703.690834 134.363174C703.691857 95.921653 672.52502 64.754816 634.083499 64.754816L634.083499 64.754816zM634.083499 64.754816"
                  fill="currentColor" p-id="1445"></path>
              </svg>
              <span>退出登录</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 登录悬浮窗 -->
    <div v-if="outerVisible2" class="dialog-overlay" @click="closeDialog"></div>
    <el-dialog v-model="outerVisible2" width="700" style="
      height: 500px;
      border-radius: 20px;
      overflow: hidden;
      background: #bdc3c7;
      background: -webkit-linear-gradient(
        to top,
        rgba(189, 199, 193, 0.6),
        rgba(32, 43, 55, 0.9)
      );
      background: linear-gradient(
        to right top,
        rgba(231, 244, 237, 0.6),
        rgba(32, 43, 55, 0.8),
        rgba(32, 43, 55, 0.9)
      );
      box-shadow: 2px 3px 20px #444f5a;
    ">
      <div class="login-dialog">
        <div class="left-section">
          <img src="../assets/home/login-background.png" alt="" />
        </div>
        <div class="right-section">
          <div class="header">
            <h3>欢迎登录</h3>
            <p>登录后每天领取免费德娃AI值</p>
          </div>
          <div class="tabs">
            <span class="active">手机登录</span>
          </div>
          <form>
            <div class="input-group">
              <input v-model="username" type="text" placeholder="请输入手机号—新用户自动注册" />
            </div>
            <div class="input-group">
              <input v-model="password" type="password" id="password-input" placeholder="请输入密码" required />
            </div>
            <p class="terms">
              登录即代表同意 <a href="#">《用户协议》</a> 和
              <a href="#">《隐私政策》</a>
            </p>
            <button type="button" class="submit-btn" @click="onSubmit()">
              登录（新用户自动注册）
            </button>
          </form>
        </div>
      </div>
    </el-dialog>
  </div>
  <div style="height: 30px; width: 100%"></div>
  <div class="box-content">
    <div class="title-container">
      <h1 class="gradient-title">
        <span class="white-text">德娃AI 视频 平台 - </span>
        <span class="gradient-text-green">私有化</span>
        <span class="gradient-text-blue">独立部署</span>
      </h1>
    </div>
    <div class="cut-off">
      <img src="../assets/syhbs/cut-off.png" alt="" />
    </div>
  </div>
  <div class="text-description">
    <img class="background-image" src="../assets/syhbs/background.png" alt="" />
    <div class="text-content">
      <div class="notification">
        <div class="notification-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        </div>
        <div class="notification-text">
          当前AI视频全民刚需，主流风口项目，迎合抖音短视频平台，流量超级大，多平台同步变现，引流超级简单！
        </div>
      </div>
      <div class="announcement">
        <p>亲爱的德娃AI 创作者，感谢大家一直以来的支持，期待与您的合作！</p>
      </div>
      <div class="feature-section">
        <h3>1、支持多端部署，多端数据同步：</h3>
        <p style="margin-top: 10px">
          官方说明
          <span class="highlight">[德娃AI正式开放私有化独立部署]</span>
          官方授权，支持德娃AI系统永久授权
          提供一份系统部署文档+后台配置对接文档（提供代部署服务（不免费））
        </p>
      </div>
      <table class="styled-table">
        <thead>
          <tr>
            <th>产品</th>
            <th>德娃AI（授权版）</th>
            <th>德娃AI（授权部署版）</th>
            <th>德娃AI（Saas版）独立部署</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>微信小程序</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>抖音小程序</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>公众号</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>H5端</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>PC电脑端</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>提供部署文档一份</td>
            <td>√</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>代部署服务（指导）</td>
            <td>×</td>
            <td>√</td>
            <td>√</td>
          </tr>
          <tr>
            <td>SaaS版（无限多开）独立部署</td>
            <td>×</td>
            <td>×</td>
            <td>√</td>
          </tr>
          <tr>
            <td>价格</td>
            <td>1388</td>
            <td>1988</td>
            <td>3988</td>
          </tr>
        </tbody>
      </table>
      <div class="feature-section">
        <h3>2、德娃AI官方客服：</h3>
      </div>
      <div class="video-container1">
        <img src="../assets/syhbs/qr-code.jpeg" alt="" />
      </div>
      <div class="announcement">
        <div class="feature-section" style="margin-left: 0; margin-bottom: 0px">
          <h3>3、核心功能：</h3>
        </div>
        <ul>
          <li>
            <span class="highlight">[视频转视频]</span>
            系统全面支持Runway最新的视频转视频功能，可以根据上传的视频自动转换成其他风格的视频出来。
          </li>
          <div class="video-container" style="margin-left: -30px">
            <video controls>
              <source src="../assets/syhbs/video2.mp4" type="video/mp4" />
            </video>
          </div>
          <li>
            <span class="highlight">[图生视频]</span>
            系统可以上传一张图片，让AI根据图片和文字，自动转换成视频。
          </li>
          <div class="video-container" style="margin-left: -30px">
            <video controls>
              <source src="../assets/home/video/aip2v.mp4" type="video/mp4" />
            </video>
          </div>
          <li>
            <span class="highlight">[文字生成视频]</span>
            根据提示词，系统可以自动生成出对应的视频出来。
          </li>
          <div class="video-container" style="margin-left: -30px">
            <video controls>
              <source src="../assets/home/video/aivideo.mp4" type="video/mp4" />
            </video>
          </div>
          <li>
            <span class="highlight">[老照片生成视频]</span>
            可以让老照片类似视频一样动起来。
          </li>
          <div class="video-container" style="margin-left: -30px">
            <video controls>
              <source src="../assets/home/video/video1.mp4" type="video/mp4" />
            </video>
          </div>
          <li>
            <span class="highlight">[跨时空拥抱视频]</span>
            可以让跨时空的两个人，实现拥抱的效果。
          </li>
          <div class="video-container" style="margin-left: -30px">
            <video controls>
              <source src="../assets/syhbs/hug.mp4" type="video/mp4" />
            </video>
          </div>
        </ul>
      </div>

      <div class="intro-section">
        <span class="feature">3. 如何快速变现：</span>
        <div class="highlight-box">
          <ul>
            <li>
              方法一：
              提供“老照片转视频”等视频生成业务，给用户进行视频生成，吸引用户体验进而付费生成。以某音及某宝为例，每次制作费用是29元-299元不等。
            </li>
            <li>
              方法二：
              系统支持无限多开SAAS站点，只要是同一授权域名下，可无限搭建，从而可以给别人多开站点进行使用。建议按年收费价格为
              1999元 以上。
            </li>
            <li>
              完整的二级分销功能，用户可以申请成为分销商赚取收益，从而进行裂变。
            </li>
          </ul>
        </div>
      </div>
      <div class="video-container">
        <img src="../assets/syhbs/1.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onBeforeUnmount } from "vue";
import axios from "axios";
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
const referralCode = ref("");
const users = ref([]);

import "./css/home.css";
const videoRefs = ref([]);
const currentTimes = ref([]);
let adVideo = ref("");
const durations = ref([]);

const iconToggled = ref(false); // 管理图标状态
const activeBox = ref("monthly"); // 初始值为 'monthly'

const showActivationDialog = ref(false); // 控制对话框显示
const activationCode = ref(""); // 存储激活码

const userid = ref(0);
const getUserinfo = () => {
  // 获取用户信息
  axios
    .get(
      "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
      openid.value
    )
    .then((res) => {
      console.log(res.data.data);
      drawNum.value = res.data.data.drawNum;
      userid.value = res.data.data.id;
      console.log(drawNum.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const activateCode = () => {
  if (!activationCode.value.trim()) {
    alert("请输入有效的激活码");
    return;
  }
  // 在这里添加激活码处理逻辑，例如向后端发送请求
  axios
    .post(
      `/genceai/?s=/api/api/run/m/xz_aivideo/do/checkcode/uniacid/10005&code=${activationCode.value}&openid=${openid.value}`
    )
    .then((res) => {
      console.log(res);

      console.log("激活码:", activationCode.value);
      alert("激活成功");
      getUserinfo();
      showActivationDialog.value = false; // 激活成功后关闭对话框
    })
    .catch((err) => {
      console.log(err);
      alert("激活失败，请检查激活码是否正确");
    });
};
const router = useRouter();
const username = ref("");
const password = ref("");
const openid = ref("");
const drawNum = ref(0);
const outerVisible2 = ref(false);
const showSuspend = ref(false); // 控制suspend盒子的显示
const isLoggedIn = ref(false); // 初始化为未登录状态
const showTooltip = ref(false); // 控制悬停提示的显示状态
const handleMouseLeave = () => {
  showSuspend.value = false;
};
const handleLogout = () => {
  isLoggedIn.value = false; // 设置未登录状态
  username.value = ""; // 清空用户名
  password.value = ""; // 清空密码
  userid.value = 0; // 清空用户id
  window.localStorage.removeItem("user"); // 清除存储的用户数据
  ElMessage({
    message: "已成功退出登录",
    type: "success",
  });
};

const handleLoginClick = () => {
  console.log("isLoggedIn:", isLoggedIn.value);

  if (!isLoggedIn.value) {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
    console.log();
  }
};
const onSubmit = () => {
  // 表单验证
  if (!username.value.trim() || !password.value.trim()) {
    alert("请输入用户名和密码！");
    return;
  }

  // 实现登录逻辑
  axios
    .post(
      `/genceai/?s=/api/api/run/m/xz_aivideo/do/adduser/uniacid/10005&username=${username.value}&password=${password.value}`
    )
    .then((res) => {
      console.log(username.value, password.value);
      console.log(res.data.data);

      if (res.data.message == "登录成功" || res.data.message == "注册成功") {
        if (res.data.message == "登录成功") {
          isLoggedIn.value = true; // 登录成功后，设置为已登录状态
          openid.value = res.data.data.openid;
          console.log(openid.value);

          axios
            .get(
              "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
              openid.value
            )
            .then((res) => {
              console.log(res.data.data);
              drawNum.value = res.data.data.drawNum;
              console.log(drawNum.value);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // 注册成功的逻辑提示
          alert("注册成功，请登录");
        }

        const userData = {
          username: username.value,
          password: password.value,
          openid: openid.value,
          drawNum: drawNum.value,
        };
        console.log("userdata:", userData);
        window.localStorage.setItem("user", JSON.stringify(userData));

        // 关闭登录弹窗
        outerVisible2.value = false;
        // 禁用点击登录按钮
        showTooltip.value = true; // 显示登录后的提示信息
      } else {
        showFailToast("密码错误，请重新输入");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
const showVideoOptionsDialog = ref(false); // 控制视频制作方式选择对话框的显示

onMounted(() => {
  window.handleLogin = () => {
    const usernameValue = document.getElementById("username-input").value;
    const passwordValue = document.getElementById("password-input").value;
    username.value = usernameValue;
    password.value = passwordValue;
    onSubmit();
  };

  const userData = JSON.parse(window.localStorage.getItem("user"));
  if (userData) {
    isLoggedIn.value = true; // 设置为已登录状态
    username.value = userData.username; // 可以选择展示用户名等信息
    openid.value = userData.openid; // 获取openid // 这里可以调用获取用户信息的函数
    getUserinfo(); // 获取用户信息
  } else {
    // 未登录状态
    isLoggedIn.value = false;
  }
});
</script>


<style scoped>
/* 头部 */
.box-header {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
}

.back {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back img {
  width: 24px;
  height: 20px;
}

.header-line {
  width: 1px;
  height: 30px;
  background-color: #333;
  margin-left: 20px;
}

.logo {
  width: 110px;
  height: 40px;
  margin-left: 20px;
}

.logo img {
  width: 100%;
  height: 100%;
}

/* 登录 */
.login {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: auto;
  cursor: pointer;
  margin-right: 80px;
}

.logged-in {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.not-login {
  font-size: 14px;
  width: 100px;
  height: 36px;
  border-radius: 20px;
  line-height: 36px;
  color: #ffffff;
  background: linear-gradient(90deg, #4a148c 0%, #8e24aa 100%);
  text-align: center;
}

.not-login:hover {
  background: linear-gradient(90deg, #6a1b9a, #ab47bc);
  transform: scale(1.05);
  /* 悬停时轻微放大 */
}

.custom-login-dialog {
  width: 700px;
  max-width: 700px;
  /* 确保对话框最大宽度 */
  height: 440px;
  max-height: 440px;
  /* 确保对话框最大高度 */
  margin: 0;
  padding: 0;
  background-color: #000;
}

.suspend {
  width: 340px;
  float: right;
  background: #bdc3c7;
  background: -webkit-linear-gradient(to top,
      rgba(189, 199, 193, 0.6),
      rgba(32, 43, 55, 0.9));
  background: linear-gradient(to right top,
      rgba(231, 244, 237, 0.6),
      rgba(32, 43, 55, 0.8),
      rgba(32, 43, 55, 0.9));
  position: fixed;
  top: 50px;
  /* 根据需要调整 */
  right: 20px;
  /* 根据需要调整 */
  z-index: 9999;
  border-radius: 10px;
}

.suspend-head {
  display: flex;
  align-items: center;
  padding: 10px;
}

.suspend-head img {
  width: 50px;
  /* 图片宽度 */
  height: 50px;
  /* 图片高度 */
  border-radius: 50%;
  /* 圆形图片 */
  margin-right: 10px;
  /* 图片与文字间距 */
}

.suspend-head span {
  color: #e8e8e8;
  /* 字体颜色 */
  font-size: 12px;
  /* 字体大小 */
}

.suspend-head a {
  /* 个人资料链接样式 */
  color: #cccccc;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  text-decoration: none;
}

.suspend-line {
  width: 90%;
  height: 1px;
  background-color: #cccccc;
  margin: 0 auto;
  margin-top: 10px;
}

.integral {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #cccccc;
  /* 字体颜色 */
  font-size: 14px;
  /* 字体大小 */
}

.integral div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.integral div span:first-child {
  display: block;
  font-size: 14px;
  /* 积分数字的字体大小 */
  color: #ffffff;
}

.integral div span:last-child {
  font-size: 14px;
  /* 描述文字的字体大小 */
  color: #cccccc;
}

.open-vip {
  width: 90%;
  margin: 0 auto;
}

.vip-content {
  background: -webkit-linear-gradient(90deg,
      rgba(44, 156, 164, 0.1),
      rgba(39, 199, 173, 0.1),
      rgba(117, 239, 156, 0.1));
  background: linear-gradient(90deg,
      rgba(44, 156, 164, 0.1),
      rgba(39, 199, 173, 0.1),
      rgba(117, 239, 156, 0.1));
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vip-text {
  color: #fff;
  font-size: 13px;
  margin-left: 10px;
  text-align: left;
}

.vip-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.box-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.button-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;
  color: #cccccc;
  font-size: 14px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  fill: #cccccc;
}

.el-message-box__wrapper {
  width: 100% !important;
  height: 100% !important;
}

.custom-login-dialog .login-dialog {
  display: flex;
  width: 100%;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  padding: 0;
  /* 取消内边距 */
  margin: 0;
  /* 取消外边距 */
}

/* 使用 ::v-deep 选择器确保修改到 el-dialog 内部样式 */
::v-deep(.activation-code-dialog) {
  width: 320px;
  height: 200px;
  margin-top: center;
  background-image: linear-gradient(252.37deg,
      #1b262b 0.55%,
      #171b21,
      #191d23 90.08%),
    linear-gradient(68.56deg,
      #1e2930 29.44%,
      #1d1d1d 59.6%,
      #262a2f 82.91%,
      #2e4141 101.21%),
    url("@/assets/home/glowing.png") !important;
  border-color: #1e3139 !important;
  padding: 24px !important;

  background-size: cover;
  /* 背景图片会根据容器大小自适应 */
  background-position: center;
  /* 背景图片居中 */
}

::v-deep(.activation-code-dialog .el-dialog__title) {
  color: #fff;
}

/* 修改 el-dialog 标题部分样式 */
::v-deep(.activation-code-dialog .el-dialog__header) {
  border-bottom: none;
  /* 移除标题区域的底部边框 */
  border-radius: 12px 12px 0 0;
  /* 圆角，只对顶部生效 */
}

/* 修改激活按钮样式 */
::v-deep(.dialog-actions .el-button) {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  color: #fff;
  /* 按钮文字颜色 */
  border-radius: 8px;
  /* 按钮圆角 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* 按钮阴影 */
}

::v-deep(.dialog-actions .el-button:hover) {
  background-color: #005bb5;
  /* 按钮悬停时的背景颜色 */
}

.left-section {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0;
  margin-top: -30px;
  margin-left: -20px;
}

.left-section img {
  width: 100%;
}

.left-section h2 {
  font-size: 20px;
  margin-top: 10px;
  color: #28fb55;
}

.right-section {
  max-width: 400px;
  height: 440px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  float: right;
  margin-top: -460px;
}

.right-section h3 {
  align-self: flex-start;
  /* 靠左对齐标题 */
  color: #fff;
  font-size: 22px;
}

.right-section p {
  font-size: 14px;
  margin-top: 20px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  /* 左对齐 */
  margin-bottom: 20px;
  margin-top: 40px;
}

.tabs span {
  cursor: pointer;
  padding-bottom: 10px;
  color: #f9f9f9;
}

.tabs .active {
  color: #28fb55;
  margin-top: 20px;
  margin: 0 auto;
  font-size: 16px;
  color: #c5c7d5;
}

.input-group {
  width: 360px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input {
  flex-grow: 1;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #333;
  color: #fff;
}

.input-group button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: #5865f2;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.terms {
  font-size: 12px;
  color: #a1a1aa;
  padding-left: 10px;
}

.terms a {
  color: #28fb55;
}

.submit-btn {
  width: 90%;
  padding: 12px;
  background-color: #5865f2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 60px;
  margin-left: 10px;
}

.box-content {
  width: 80%;
  margin: 0 auto;
}

.title-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-title {
  font-size: 36px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  text-align: center;
}

.white-text {
  color: #ffffff;
}

.gradient-text-green {
  background: linear-gradient(to right, #00ff9d, #00ffcc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #00ff9d;
  /* Fallback for browsers that don't support gradient text */
}

.gradient-text-blue {
  background: linear-gradient(to right, #00ffcc, #00a3ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #00a3ff;
  /* Fallback for browsers that don't support gradient text */
}

.cut-off {
  height: 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.text-description {
  width: 80%;
  background: linear-gradient(180deg,
      rgba(24, 28, 32, 1) 0%,
      rgba(2, 12, 18, 1) 100%);
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
}

.background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  /* 根据需要调整大小 */
  height: auto;
  /* 根据需要调整大小 */
  z-index: 1;
  /* 确保图片在文字后面 */
  pointer-events: none;
  /* 点击事件穿透 */
}

.notification {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin: 20px;
}

.notification-icon {
  color: #4dd0e1;
  margin-right: 15px;
}

.notification-text {
  font-size: 20px;
  background: linear-gradient(to right, #fff, #fff, #4dd0e1, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  padding: 10px;
}

.announcement {
  color: #e0e0e0;
  line-height: 1.6;
  margin-left: 60px;
}

.announcement p {
  margin-bottom: 1em;
  font-size: 18px;
}

.announcement ul {
  font-size: 18px;
  list-style-type: none;
  padding: 0;
}

.announcement li {
  margin-left: 30px;
  margin-bottom: 0.5em;
  list-style-type: disc;
}

.highlight {
  background: linear-gradient(to right, #ffb74d, #ff9800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.feature {
  background: linear-gradient(to right, #64ffda, #00bfa5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.feature-section {
  font-size: 14px;
  color: #e0e0e0;
  /* margin-bottom: 20px;
  margin-left: 60px;
  margin-top: 20px;
  margin-right: 60px; */
  margin: 20px 60px;
}

.feature-section h3 {
  font-size: 20px;
  background: linear-gradient(to right, #64ffda, #00bfa5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  /* margin-bottom: 20px; */
  padding: 20px 0;
}

.feature-section p {
  line-height: 1.6;
  font-size: 18px;
}

.highlight {
  background: linear-gradient(to right, #ffb74d, #ff9800);
  /* 橙色渐变 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.gradient-box {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(to right, #1b2906, #162817, #0f2425);
  z-index: 1;
}

.gradient-box::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  background: linear-gradient(to right, #a2fe2f, #73f0d1, #49d5fc);
  z-index: -1;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  padding: 2px;
}

.gradient-box ul {
  list-style: disc;
  /* 恢复圆点 */
  padding-left: 20px;
  /* 给圆点留出空间 */
}

.gradient-box li {
  padding: 10px;
  font-size: 20px;
  letter-spacing: 2px;
  color: transparent;
  background: linear-gradient(to right, #fff, #4dd0e1, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
}

.gradient-box li::marker {
  color: white;
  /* 圆点颜色 */
}

.note {
  margin-top: 10px;
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 20px;
}

.video-container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 600px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: inline-block;
  /* 使容器适应内容 */
  border: 1px solid transparent;
  /* 设置边框宽度 */
  border-radius: 10px;
  /* 设置圆角边框 */
  background: linear-gradient(to right,
      #a2fe2f,
      #73f0d1,
      #49d5fc);
  /* 渐变背景 */
  padding: 1.5px;
  /* 内边距，让视频与边框保持距离 */
}

.video-container1 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: inline-block;
  /* 使容器适应内容 */
  border: 1px solid transparent;
  /* 设置边框宽度 */
  border-radius: 10px;
  /* 设置圆角边框 */
  background: linear-gradient(to right,
      #a2fe2f,
      #73f0d1,
      #49d5fc);
  /* 渐变背景 */
  padding: 1.5px;
  /* 内边距，让视频与边框保持距离 */
}

.video-container video {
  width: 100%;
  height: auto;
  display: block;
  /* 确保视频充满容器 */
  border-radius: 5px;
  /* 视频的圆角 */
}

.video-container img {
  width: 100%;
  height: auto;
  display: block;
  /* 确保视频充满容器 */
  border-radius: 5px;
  /* 视频的圆角 */
}

.video-container1 img {
  width: 100%;
  height: auto;
  display: block;
  /* 确保视频充满容器 */
  border-radius: 5px;
  /* 视频的圆角 */
}

.styled-table {
  width: 90%;
  border-collapse: collapse;
  margin: 20px auto;
  color: #f5f7f6;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.styled-table th,
.styled-table td {
  padding: 15px;
  text-align: center;
  border: 1px solid #265d5f;
}

.styled-table th {
  background-color: rgb(18, 31, 30);
  font-weight: bold;
}

.intro-section {
  color: #f5f7f6;
  /* 字体颜色 */
  margin: 20px 60px;
  border-radius: 5px;
  font-size: 20px;
  line-height: 1.6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.highlight-box {
  width: 90%;
  /* 更新宽度 */
  margin: 20px auto;
  /* 居中对齐 */
  position: relative;
  padding: 15px;
  border-radius: 8px;
  /* 更新圆角 */
  background: linear-gradient(to right,
      #1b2906,
      #162817,
      #0f2425);
  /* 背景渐变 */
  z-index: 1;
  /* 确保内容在前 */
  font-size: 16px;
}

.highlight-box::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  /* 圆角与主容器一致 */
  background: linear-gradient(to right,
      #a2fe2f,
      #73f0d1,
      #49d5fc);
  /* 渐变边框 */
  z-index: -1;
  /* 边框在下层 */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  padding: 2px;
  /* 边框的内边距 */
}

.highlight-box ul {
  list-style-type: none;
  /* 去掉默认的列表样式 */
  padding-left: 20px;
  /* 留出空间 */
}

.highlight-box li {
  margin-bottom: 10px;
  /* 列表项底部间距 */
  position: relative;
  padding-left: 20px;
  /* 列表项左侧内边距 */
  color: #fff;
  /* 将文字颜色改为白色 */
}

.highlight-box li::before {
  content: "•";
  /* 自定义项目符号 */
  color: #fff;
  /* 项目符号颜色 */
  position: absolute;
  left: 0;
  top: 0;
}

.highlight-box .icon {
  background-color: #333;
  /* 保留图标背景颜色 */
  border-radius: 40%;
  /* 图标圆角 */
  width: 30px;
  /* 图标宽度 */
  height: 30px;
  /* 图标高度 */
  padding: 5px;
  /* 图标内边距 */
  display: inline-flex;
  /* 使用 flexbox 对齐图标 */
  align-items: center;
  /* 垂直居中 */
  justify-content: center;
  /* 水平居中 */
  margin-bottom: -10px;
  /* 调整位置 */
}
</style>