<template>
  <div class="home">
    <div class="page-content-wrapper">
  <div class="box-header">
    <a href="/">
    <div class="back">
      <img src="../../assets/syhbs/back.png" alt="" />
    </div>
    </a>
    <div class="header-line"></div>
    <div class="logo">
      <img src="../../assets/home/LOGO.png" alt="" />
    </div>
    <!-- 登录 -->
          <div class="login" plain @click="handleLoginClick" @mouseover="showSuspend = true"
            @mouseleave="handleMouseLeave">
            <template v-if="isLoggedIn">
              <img src="../../assets/home/login.jpg" alt="已登录" style="
                  float: right;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  margin-right: -40px;
                " />
            </template>
            <template v-else>
              <div class="not-login">登录</div>
            </template>

            <!-- 悬停提示盒子 -->
            <div v-if="showSuspend && isLoggedIn" class="suspend" @mouseover="showSuspend = true"
              @mouseleave="showSuspend = false">
              <template v-if="isLoggedIn">
                <div class="suspend-head">
                  <img src="../../assets/home/login.jpg" alt="" />
                  <span>用户名:{{ username }}</span>
                  <a href="/myvideos">个人资料&nbsp;></a>
                </div>
                <div class="suspend-line"></div>
                <div class="integral">
                    <div>
                    <span>{{ userid }}</span>
                    <span>用户ID</span>
                  </div>
                  <div>
                    <span>{{ drawNum }}</span>
                    <span>积分详情&nbsp;></span>
                  </div>
                
                </div>
              </template>
            </div>
          </div>
  </div>
      <el-tabs
        type="border-card"
        class="demo-tabs"
        v-model="activeTab"
        style="
          border: none;
          background-color: #000;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        "
      >
        <el-tab-pane name="recommend">
          <template #label>
            <span class="custom-tabs-label" style="font-weight: bold">
              推荐视频
            </span>
          </template>
          <div class="big-card">
            <div
              class="video-container"
              v-for="(item, index) in recommendList"
              :key="index"
              @mouseenter="playVideo(index)"
              @mouseleave="pauseVideo(index)"
            >
              <div class="video-wrapper">
                <video
                  :ref="
                    (el) => {
                      if (el) videoRefs[index] = el;
                    }
                  "
                  @timeupdate="updateProgress(index)"
                  @loadedmetadata="updateDuration(index)"
                  loop
                  muted
                >
                  <source
                    :src="item.toadVideo.replace('http:', 'https:')"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div class="overlay"></div>
              <!-- 
                <div class="top-controls">
                  <button class="icon-button favorite">
                    <Trash2 />
                  </button>
                </div> -->

              <div class="bottom-controls">
                <div class="duration">
                  {{ formatTime(currentTimes[index] || 0) }}/{{
                    formatTime(durations[index] || 0)
                  }}
                </div>
                <button class="icon-button expand" @click="openModal(item.toadVideo.replace('http:', 'https:'))">
                  <MaximizeIcon />
                </button>
              </div>

              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{
                    width: `${
                      ((currentTimes[index] || 0) / (durations[index] || 1)) *
                      100
                    }%`,
                  }"
                ></div>
              </div>

              <div class="controls">
                <button @click="retryVideo(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M1 4v6h6"></path>
                    <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                  </svg>
                  重绘
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="copy(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  Reprompt
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path
                      d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
                    ></path>
                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                  </svg>
                  Edit
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                  Add 4s
                </button>
              </div>
              <div class="video-info">
                <div class="copy">
                  <div class="copy-te" @click="copy(index)">
                    <svg
                      t="1726662330010"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="18749"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M394.666667 106.666667h448a74.666667 74.666667 0 0 1 74.666666 74.666666v448a74.666667 74.666667 0 0 1-74.666666 74.666667H394.666667a74.666667 74.666667 0 0 1-74.666667-74.666667V181.333333a74.666667 74.666667 0 0 1 74.666667-74.666666z m0 64a10.666667 10.666667 0 0 0-10.666667 10.666666v448a10.666667 10.666667 0 0 0 10.666667 10.666667h448a10.666667 10.666667 0 0 0 10.666666-10.666667V181.333333a10.666667 10.666667 0 0 0-10.666666-10.666666H394.666667z m245.333333 597.333333a32 32 0 0 1 64 0v74.666667a74.666667 74.666667 0 0 1-74.666667 74.666666H181.333333a74.666667 74.666667 0 0 1-74.666666-74.666666V394.666667a74.666667 74.666667 0 0 1 74.666666-74.666667h74.666667a32 32 0 0 1 0 64h-74.666667a10.666667 10.666667 0 0 0-10.666666 10.666667v448a10.666667 10.666667 0 0 0 10.666666 10.666666h448a10.666667 10.666667 0 0 0 10.666667-10.666666v-74.666667z"
                        fill="#ffffff"
                        p-id="18750"
                      ></path>
                    </svg>
                    <span class="copy-a1">Copy</span>
                  </div>
                  <div class="copy-a2">{{ item.title }}</div>
                </div>
                <div class="copy-a3">
                  <svg
                    t="1726711646686"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="20035"
                    width="20"
                    height="20"
                    style="cursor: pointer"
                  >
                    <path
                      d="M1009.777778 503.466667l-443.733334-455.111111c-5.688889-5.688889-11.377778 0-11.377777 5.688888v267.377778C8.533333 409.6 2.844444 918.755556 17.066667 932.977778c0 0 45.511111-48.355556 164.977777-113.777778 85.333333-48.355556 224.711111-85.333333 369.777778-102.4v261.688889c0 8.533333 11.377778 11.377778 14.222222 5.688889l443.733334-480.711111z m-398.222222 358.4v-199.111111l-36.977778-2.844445c-221.866667 8.533333-378.311111 73.955556-497.777778 156.444445 76.8-275.911111 267.377778-403.911111 466.488889-438.044445l68.266667-2.844444v-199.111111l312.888888 312.888888s8.533333 5.688889 8.533334 14.222223-8.533333 14.222222-8.533334 14.222222l-312.888888 344.177778z"
                      fill="#cdcdcd"
                      p-id="20036"
                    ></path>
                  </svg>
                  <svg
                    t="1726711770348"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="24025"
                    width="20"
                    height="20"
                    style="margin-left: 20px; cursor: pointer"
                  >
                    <path
                      d="M501.5 109.38a403.52 403.52 0 0 1 285.32 688.84 403.52 403.52 0 0 1-570.66-570.66 400.94 400.94 0 0 1 285.34-118.18m0-64C243.3 45.38 34 254.7 34 512.88S243.3 980.4 501.5 980.4 969 771.08 969 512.88 759.68 45.38 501.5 45.38z"
                      fill="#cdcdcd"
                      p-id="24026"
                    ></path>
                    <path
                      d="M501.5 291.16a7.64 7.64 0 1 1-7.64 7.64 7.64 7.64 0 0 1 7.64-7.64m0-64a71.64 71.64 0 1 0 71.62 71.64 71.64 71.64 0 0 0-71.62-71.64zM501.5 418.18a59.38 59.38 0 0 0-59.38 59.38V768a59.36 59.36 0 0 0 59.38 59.36A59.36 59.36 0 0 0 560.86 768V477.56a59.36 59.36 0 0 0-59.36-59.38z"
                      fill="#cdcdcd"
                      p-id="24027"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="horizontal">
          <template #label>
            <span class="custom-tabs-label">
              &nbsp;&nbsp;&nbsp;竖&nbsp;&nbsp;版&nbsp;&nbsp;&nbsp;
            </span>
          </template>
          <div class="big-card-shu">
            <div
              class="video-container"
              v-for="(item, index) in recommendList"
              :key="index"
              @mouseenter="playVideo1(index)"
              @mouseleave="pauseVideo1(index)"
            >
              <div class="video-wrapper">
                <video
                  :ref="
                    (el) => {
                      if (el) videoRefs1[index] = el;
                    }
                  "
                  @timeupdate="updateProgress1(index)"
                  @loadedmetadata="updateDuration1(index)"
                  loop
                  muted
                >
                  <source
                    :src="item.toadVideo.replace('http:', 'https:')"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div class="overlay"></div>
              <!-- 
                <div class="top-controls">
                  <button class="icon-button favorite">
                    <Trash2 />
                  </button>
                </div> -->

              <div class="bottom-controls">
                <div class="duration">
                  {{ formatTime(currentTimes[index] || 0) }}/{{
                    formatTime(durations[index] || 0)
                  }}
                </div>
                <button
                  class="icon-button expand"
                  @click="openModal(item.toadVideo.replace('http:', 'https:'))"
                >
                  <MaximizeIcon />
                </button>
              </div>

              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{
                    width: `${
                      ((currentTimes[index] || 0) / (durations[index] || 1)) *
                      100
                    }%`,
                  }"
                ></div>
              </div>

              <div class="controls">
                <button @click="retryVideo(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M1 4v6h6"></path>
                    <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                  </svg>
                  重绘
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="copy(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  Reprompt
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path
                      d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
                    ></path>
                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                  </svg>
                  Edit
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                  Add 4s
                </button>
              </div>
              <div class="video-info">
                <div class="copy">
                  <div class="copy-te" @click="copy(index)">
                    <svg
                      t="1726662330010"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="18749"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M394.666667 106.666667h448a74.666667 74.666667 0 0 1 74.666666 74.666666v448a74.666667 74.666667 0 0 1-74.666666 74.666667H394.666667a74.666667 74.666667 0 0 1-74.666667-74.666667V181.333333a74.666667 74.666667 0 0 1 74.666667-74.666666z m0 64a10.666667 10.666667 0 0 0-10.666667 10.666666v448a10.666667 10.666667 0 0 0 10.666667 10.666667h448a10.666667 10.666667 0 0 0 10.666666-10.666667V181.333333a10.666667 10.666667 0 0 0-10.666666-10.666666H394.666667z m245.333333 597.333333a32 32 0 0 1 64 0v74.666667a74.666667 74.666667 0 0 1-74.666667 74.666666H181.333333a74.666667 74.666667 0 0 1-74.666666-74.666666V394.666667a74.666667 74.666667 0 0 1 74.666666-74.666667h74.666667a32 32 0 0 1 0 64h-74.666667a10.666667 10.666667 0 0 0-10.666666 10.666667v448a10.666667 10.666667 0 0 0 10.666666 10.666666h448a10.666667 10.666667 0 0 0 10.666667-10.666666v-74.666667z"
                        fill="#ffffff"
                        p-id="18750"
                      ></path>
                    </svg>
                    <span class="copy-a1">Copy</span>
                  </div>
                  <div class="copy-a2">{{ item.title }}</div>
                </div>
                <div class="copy-a3">
                  <svg
                    t="1726711646686"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="20035"
                    width="20"
                    height="20"
                    style="cursor: pointer"
                  >
                    <path
                      d="M1009.777778 503.466667l-443.733334-455.111111c-5.688889-5.688889-11.377778 0-11.377777 5.688888v267.377778C8.533333 409.6 2.844444 918.755556 17.066667 932.977778c0 0 45.511111-48.355556 164.977777-113.777778 85.333333-48.355556 224.711111-85.333333 369.777778-102.4v261.688889c0 8.533333 11.377778 11.377778 14.222222 5.688889l443.733334-480.711111z m-398.222222 358.4v-199.111111l-36.977778-2.844445c-221.866667 8.533333-378.311111 73.955556-497.777778 156.444445 76.8-275.911111 267.377778-403.911111 466.488889-438.044445l68.266667-2.844444v-199.111111l312.888888 312.888888s8.533333 5.688889 8.533334 14.222223-8.533333 14.222222-8.533334 14.222222l-312.888888 344.177778z"
                      fill="#cdcdcd"
                      p-id="20036"
                    ></path>
                  </svg>
                  <svg
                    t="1726711770348"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="24025"
                    width="20"
                    height="20"
                    style="margin-left: 20px; cursor: pointer"
                  >
                    <path
                      d="M501.5 109.38a403.52 403.52 0 0 1 285.32 688.84 403.52 403.52 0 0 1-570.66-570.66 400.94 400.94 0 0 1 285.34-118.18m0-64C243.3 45.38 34 254.7 34 512.88S243.3 980.4 501.5 980.4 969 771.08 969 512.88 759.68 45.38 501.5 45.38z"
                      fill="#cdcdcd"
                      p-id="24026"
                    ></path>
                    <path
                      d="M501.5 291.16a7.64 7.64 0 1 1-7.64 7.64 7.64 7.64 0 0 1 7.64-7.64m0-64a71.64 71.64 0 1 0 71.62 71.64 71.64 71.64 0 0 0-71.62-71.64zM501.5 418.18a59.38 59.38 0 0 0-59.38 59.38V768a59.36 59.36 0 0 0 59.38 59.36A59.36 59.36 0 0 0 560.86 768V477.56a59.36 59.36 0 0 0-59.36-59.38z"
                      fill="#cdcdcd"
                      p-id="24027"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane style="text-align: center" name="myvideo">
          <template #label>
            <span class="custom-tabs-label">
              <!-- musicUrl -->
              我的视频
            </span>
          </template>
          <div
            class="big-card"
            ref="videoScrollContainer"
            @scroll="handleScroll"
          >
            <div class="video-container1" v-for="(item, index) in deng">
              <div class="video-editing-container">
                <div class="video-frame">
                  <div class="video-content">
                    <div class="scene"></div>
                    <div class="overlay1"></div>
                  </div>
                  <div class="progress-bar1">
                    <div class="progress1"></div>
                  </div>
                </div>
                <div class="tools">
                  <div class="tool-icon cut">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M20 5L5 20"></path>
                      <path d="M9 6.5L5 10.5"></path>
                      <path d="M14 6.5L18 10.5"></path>
                      <path d="M9 17.5L5 13.5"></path>
                      <path d="M14 17.5L18 13.5"></path>
                    </svg>
                  </div>
                  <div class="tool-icon effects">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polygon
                        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                      ></polygon>
                    </svg>
                  </div>
                  <div class="tool-icon audio">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"
                      ></path>
                      <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                      <line x1="12" y1="19" x2="12" y2="23"></line>
                      <line x1="8" y1="23" x2="16" y2="23"></line>
                    </svg>
                  </div>
                </div>
                <p class="loading-text">视频制作中...</p>
              </div>
            </div>
            <div
              class="video-container"
              v-for="(item, index) in musicUrl"
              :key="index"
            >
              <div class="video-wrapper">
                <video
                  @mouseenter="playVideo2($event.target)"
                  :ref="
                    (el) => {
                      if (el) videoRefs2[index] = el;
                    }
                  "
                  @mouseleave="pauseVideo2($event.target)"
                  @timeupdate="updateProgress2(index)"
                  @loadedmetadata="updateDuration2(index)"
                  loop
                  muted
                >
                  <source
                    :src="item.videopath_ol.replace('http:', 'https:')"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div class="overlay"></div>

              <div class="top-controls">
                <button
                  class="icon-button favorite"
                  @click="deleteVideo(musicUrl[index].orderid)"
                >
                  <Trash2 />
                </button>
              </div>

              <div class="bottom-controls">
                <div class="duration">
                  {{ formatTime2(currentTimes2[index] || 0) }}/{{
                    formatTime2(durations2[index] || 0)
                  }}
                </div>
                <button
                  class="icon-button expand"
                  @click="openModal1(item.videopath_ol.replace('http:', 'https:'))"
                >
                  <MaximizeIcon />
                </button>
              </div>

              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{
                    width: `${
                      ((currentTimes2[index] || 0) / (durations2[index] || 1)) *
                      100
                    }%`,
                  }"
                ></div>
              </div>

              <div class="controls">
                <button @click="retryVideo(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M1 4v6h6"></path>
                    <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                  </svg>
                  重绘
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="copy1(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  Reprompt
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <path
                      d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
                    ></path>
                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                  </svg>
                  Edit
                </button>
                <el-divider class="gaoji1" direction="vertical" />
                <button @click="openwork">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5 a1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                  Add 4s
                </button>
              </div>
              <div class="video-info">
                <div class="copy">
                  <div class="copy-te" @click="copy1(index)">
                    <svg
                      t="1726662330010"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="18749"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M394.666667 106.666667h448a74.666667 74.666667 0 0 1 74.666666 74.666666v448a74.666667 74.666667 0 0 1-74.666666 74.666667H394.666667a74.666667 74.666667 0 0 1-74.666667-74.666667V181.333333a74.666667 74.666667 0 0 1 74.666667-74.666666z m0 64a10.666667 10.666667 0 0 0-10.666667 10.666666v448a10.666667 10.666667 0 0 0 10.666667 10.666667h448a10.666667 10.666667 0 0 0 10.666666-10.666667V181.333333a10.666667 10.666667 0 0 0-10.666666-10.666666H394.666667z m245.333333 597.333333a32 32 0 0 1 64 0v74.666667a74.666667 74.666667 0 0 1-74.666667 74.666666H181.333333a74.666667 74.666667 0 0 1-74.666666-74.666666V394.666667a74.666667 74.666667 0 0 1 74.666666-74.666667h74.666667a32 32 0 0 1 0 64h-74.666667a10.666667 10.666667 0 0 0-10.666666 10.666667v448a10.666667 10.666667 0 0 0 10.666666 10.666666h448a10.666667 10.666667 0 0 0 10.666667-10.666666v-74.666667z"
                        fill="#ffffff"
                        p-id="18750"
                      ></path>
                    </svg>
                    <span class="copy-a1">Copy</span>
                  </div>
                  <div class="copy-a2">{{ item.prompt }}</div>
                </div>
                <div class="copy-a3">
                  <svg
                    t="1726711646686"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="20035"
                    width="20"
                    height="20"
                    style="cursor: pointer"
                  >
                    <path
                      d="M1009.777778 503.466667l-443.733334-455.111111c-5.688889-5.688889-11.377778 0-11.377777 5.688888v267.377778C8.533333 409.6 2.844444 918.755556 17.066667 932.977778c0 0 45.511111-48.355556 164.977777-113.777778 85.333333-48.355556 224.711111-85.333333 369.777778-102.4v261.688889c0 8.533333 11.377778 11.377778 14.222222 5.688889l443.733334-480.711111z m-398.222222 358.4v-199.111111l-36.977778-2.844445c-221.866667 8.533333-378.311111 73.955556-497.777778 156.444445 76.8-275.911111 267.377778-403.911111 466.488889-438.044445l68.266667-2.844444v-199.111111l312.888888 312.888888s8.533333 5.688889 8.533334 14.222223-8.533333 14.222222-8.533334 14.222222l-312.888888 344.177778z"
                      fill="#cdcdcd"
                      p-id="20036"
                    ></path>
                  </svg>
                  <svg
                    t="1726711770348"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="24025"
                    width="20"
                    height="20"
                    style="margin-left: 20px; cursor: pointer"
                  >
                    <path
                      d="M501.5 109.38a403.52 403.52 0 0 1 285.32 688.84 403.52 403.52 0 0 1-570.66-570.66 400.94 400.94 0 0 1 285.34-118.18m0-64C243.3 45.38 34 254.7 34 512.88S243.3 980.4 501.5 980.4 969 771.08 969 512.88 759.68 45.38 501.5 45.38z"
                      fill="#cdcdcd"
                      p-id="24026"
                    ></path>
                    <path
                      d="M501.5 291.16a7.64 7.64 0 1 1-7.64 7.64 7.64 7.64 0 0 1 7.64-7.64m0-64a71.64 71.64 0 1 0 71.62 71.64 71.64 71.64 0 0 0-71.62-71.64zM501.5 418.18a59.38 59.38 0 0 0-59.38 59.38V768a59.36 59.36 0 0 0 59.38 59.36A59.36 59.36 0 0 0 560.86 768V477.56a59.36 59.36 0 0 0-59.36-59.38z"
                      fill="#cdcdcd"
                      p-id="24027"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <video
            ref="modalVideoRef"
            @timeupdate="updateModalProgress"
            controls
            autoplay
          >
            <source :src="currentModalVideo" type="video/mp4" />
          </video>
          <button class="close-button" @click="closeModal">
            <XIcon />
          </button>
        </div>
      </div>
      <div class="dialogBox-body">
        <div class="dialogBox-body-content">
          <div style="width: 20%"></div>
          <input
            type="text"
            name="text"
            v-model="prompt"
            placeholder="在此描述画面的关键词"
            class="input"
          />
          <button
            class="but"
            @click="
              add();
              openFullScreen();
            "
          >
            立即生成
          </button>
          <div style="width: 20%"></div>
        </div>
        <div class="dialogBox-body1">
          <el-button plain @click="outerVisible = true" class="gaoji">
            <img
              src="../../assets/work/a1.svg"
              alt="SVG Image"
              width="15"
              height="15"
              style="margin-right: 10px"
            />
            参数设置
          </el-button>
          <el-divider class="gaoji1" direction="vertical" />
          <div class="select" @click="openwork">
            <el-button plain class="tishiyu">
              <img
                src="../../assets/work/more.svg"
                alt="SVG Image"
                width="20"
                height="20"
              />
            </el-button>
            <span class="a1">推荐描述语</span>
          </div>
          <div class="bang" @click="faq = true">
            <img
              src="../../assets/work/a2.svg"
              alt="SVG Image"
              width="40"
              height="40"
            />
          </div>
          <div class="yun" @click="outerVisible3 = true">
            <img
              src="../../assets/work/6.svg"
              alt="SVG Image"
              width="32"
              height="32"
            />
            <span class="a1">运镜控制</span>
          </div>
          <!-- 参数设置弹框 -->
          <el-dialog
            v-model="outerVisible"
            width="500"
            style="
              height: 500px;
              border-radius: 20px;
              background: #bdc3c7;
              background: -webkit-linear-gradient(
                to top,
                rgba(189, 199, 193, 0.6),
                rgba(32, 43, 55, 0.9)
              );
              background: linear-gradient(
                to right top,
                rgba(231, 244, 237, 0.6),
                rgba(32, 43, 55, 0.8),
                rgba(32, 43, 55, 0.9)
              );
              box-shadow: 2px 3px 20px #444f5a;
            "
          >
            <div class="dilaog-box">
              <h2 style="text-align: center">参数设置</h2>
              <div class="box-wai">
                <span class="text-lg">创意想象力：</span>
                <div class="slider-container">
                  <input
                    type="range"
                    :min="min"
                    :max="max"
                    :step="step"
                    v-model="localValue"
                    @input="updateValue"
                    class="slider"
                    :style="{ background: sliderBackground }"
                    :aria-valuenow="localValue"
                    :aria-valuemin="min"
                    :aria-valuemax="max"
                  />
                  <div class="value-display">{{ localValue }}</div>
                </div>
              </div>
              <div class="upload-container">
                <span class="text-lg">上传图片:</span>
                <ImageUploader @image-uploaded="onImageUploaded" />
              </div>
              <div class="aout-container">
                <span class="text-lg">所需积分:</span>
                <span class="text-lg1">{{ drawNumdeplete }}</span>
              </div>
              <div class="aout-container1">
                <span class="text-lg">我的积分:</span>
                <span class="text-lg1">{{ drawNum }}</span>
              </div>
            </div>
            <button class="button-dialog" @click="outerVisible = false">
              确定
            </button>
          </el-dialog>
          <!-- 提示语弹框 -->
          <el-dialog
            v-model="outerVisible1"
            width="700"
            style="
              height: 500px;
              border-radius: 20px;
              background: #bdc3c7;
              background: -webkit-linear-gradient(
                to top,
                rgba(189, 199, 193, 0.6),
                rgba(32, 43, 55, 0.9)
              );
              background: linear-gradient(
                to right top,
                rgba(231, 244, 237, 0.6),
                rgba(32, 43, 55, 0.8),
                rgba(32, 43, 55, 0.9)
              );
              box-shadow: 2px 3px 20px #444f5a;
            "
          >
            <div class="drawer-content">
              <img
                src="../../assets/work/1.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小猫')"
              />
              <img
                src="../../assets/work/2.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小狗')"
              />
              <img
                src="../../assets/work/3.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸡')"
              />
              <img
                src="../../assets/work/4.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸭')"
              />
              <img
                src="../../assets/work/1.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小猫')"
              />
              <img
                src="../../assets/work/2.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小狗')"
              />
              <img
                src="../../assets/work/3.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸡')"
              />
              <img
                src="../../assets/work/4.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸭')"
              />
              <img
                src="../../assets/work/1.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小猫')"
              />
              <img
                src="../../assets/work/2.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小狗')"
              />
              <img
                src="../../assets/work/3.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸡')"
              />
              <img
                src="../../assets/work/4.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸭')"
              />
              <img
                src="../../assets/work/1.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小猫')"
              />
              <img
                src="../../assets/work/2.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小狗')"
              />
              <img
                src="../../assets/work/3.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸡')"
              />
              <img
                src="../../assets/work/4.svg"
                alt="SVG Image"
                width="50"
                height="50"
                @click="setText('小鸭')"
              />
            </div>
          </el-dialog>
          <!-- 帮助弹框 -->
          <el-dialog
            v-model="outerVisible2"
            width="700"
            style="
              height: 500px;
              border-radius: 20px;
              background: #bdc3c7;
              background: -webkit-linear-gradient(
                to top,
                rgba(189, 199, 193, 0.6),
                rgba(32, 43, 55, 0.9)
              );
              background: linear-gradient(
                to right top,
                rgba(231, 244, 237, 0.6),
                rgba(32, 43, 55, 0.8),
                rgba(32, 43, 55, 0.9)
              );
              box-shadow: 2px 3px 20px #444f5a;
            "
          >
          </el-dialog>
          <!-- 运镜方式： -->
          <el-dialog
            v-model="outerVisible3"
            width="600"
            style="
              height: 500px;
              border-radius: 20px;
              background: #bdc3c7;
              background: -webkit-linear-gradient(
                to top,
                rgba(189, 199, 193, 0.6),
                rgba(32, 43, 55, 0.9)
              );
              background: linear-gradient(
                to right top,
                rgba(231, 244, 237, 0.6),
                rgba(32, 43, 55, 0.8),
                rgba(32, 43, 55, 0.9)
              );
              box-shadow: 2px 3px 20px #444f5a;
            "
          >
            <div class="camera-movement-control">
              <div class="preview-container">
                <div class="preview-image">
                  <div
                    class="focus-rectangle"
                    :style="focusRectangleStyle"
                  ></div>
                </div>
              </div>
              <div class="controls1">
                <div class="movement-type">
                  <label for="movement-select">运镜方式：</label>
                  <select id="movement-select" v-model="movementType">
                    <option>无</option>
                    <option>水平运镜</option>
                    <option>垂直运镜</option>
                    <option>拉远/推进</option>
                    <option>垂直摇镜</option>
                    <option>水平摇镜</option>
                    <option>旋转运镜</option>
                  </select>
                  <button @click="resetMovement">重置</button>
                  <button @click="determine">确定</button>
                </div>
                <div class="movement-slider">
                  <input
                    type="range"
                    v-model.number="movementValue"
                    :min="movementRange.min"
                    :max="movementRange.max"
                    :step="movementRange.step"
                  />
                  <input
                    type="number"
                    v-model.number="movementValue"
                    :min="movementRange.min"
                    :max="movementRange.max"
                    :step="movementRange.step"
                  />
                </div>
              </div>
            </div>
          </el-dialog>
                    <div v-if="faq" class="dialog-overlay" @click="closeDialog"></div>

          <el-dialog
            v-model="faq"
            width="400px"
            style="
              height: 400px;
              border-radius: 20px;
              box-shadow: 2px 3px 20px #444f5a;
              margin-top: 200px;
              padding: 20px;
            "
          >
            <div
              class="faq"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <img src="../../assets/syhbs/qr-code.jpeg" alt="" />
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
  <script setup>
import axios from "axios";
import {
  ref,
  computed,
  watch,
  onMounted,
  reactive,
  onBeforeUnmount,
} from "vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { UploadFilled, Calendar } from "@element-plus/icons-vue";
import { StarIcon, MaximizeIcon, XIcon, Trash2 } from "lucide-vue-next";
import ImageUploader from "../../components/ImageUploader.vue";
import { nextTick } from "vue";
const outerVisible = ref(false);
const outerVisible1 = ref(false);
const outerVisible2 = ref(false);
const prompt = ref("");
const value = ref("Mon");
const videoRefs = ref([]);
const videoRefs1 = ref([]);
const videoRefs2 = ref([]);
const currentTimes = ref([]);
const currentTimes2 = ref([]);
const durations = ref([]);
const durations2 = ref([]);
const isModalOpen = ref(false);
const currentModalVideo = ref("");
const outerVisible3 = ref(false);
const faq = ref(false);
const movementType = ref("水平运镜");
const movementValue = ref(0);
const imageUrl = ref(null);
import { getRecommendVideo } from "../api/api.js";

const recommendList = ref([]);
const page = ref(1);
const hasMore = ref(true);
const isFetching = ref(false); // 更改变量名
const isBottomReached = ref(false); // 新增变量标记是否触底

// 获取推荐视频
const getRecommend = async () => {
  if (isFetching.value || !hasMore.value) return; // 避免重复请求

  isFetching.value = true;

  // 重置 foundLuma 标志位
  let foundLuma = false;

  while (hasMore.value) {
    const res = await getRecommendVideo(page.value);
    if (!Array.isArray(res)) {
      console.error("getRecommendVideo 返回的结果不是一个数组", res);
      isFetching.value = false;
      return;
    }

    if (res.length === 0) {
      hasMore.value = false; // 没有更多数据
      isFetching.value = false;
      return;
    }

    // 新版本临时保存的列表
    const newVideos = [];

    for (let i = 0; i < res.length; i++) {
      newVideos.push({
        id: res[i].id,
        toadVideo: res[i].videopath_ol,
        imgsrc: res[i].imgpath.includes("https://video.genceai.com/uploads/")
          ? res[i].imgpath
          : "https://video.genceai.com/uploads/" + res[i].imgpath,
        title: res[i].musicword,
        mv: "",
        tags: "",
      });
    }

    // 获取详细信息
    const requests = newVideos.map((video) =>
      axios
        .get(
          "/genceai/?s=/api/api/run/m/xz_aivideo/do/showmusic/uniacid/10005/&id=" +
            video.id
        )
        .then((res) => {
          return res.data.data;
        })
    );

    await Promise.all(requests)
      .then((responses) => {
        responses.forEach((response, index) => {
          if (response.orderobj) {
            newVideos[index].mv = response.orderobj.mv;
            newVideos[index].tags = response.orderobj.tags;
          }
        });

        // 过滤出 mv 为 luma 的视频
        const lumaVideos = newVideos.filter(
          (video) => video.mv.trim().toLowerCase() === "runway"
        );

        // 如果找到 luma 视频，更新状态且推入推荐列表
        if (lumaVideos.length > 0) {
          foundLuma = true;
          // 用 Set 检查已存在的推荐列表中视频的 ID
          const existingIds = new Set(
            recommendList.value.map((video) => video.id)
          );

          lumaVideos.forEach((video) => {
            // 如果 ID 不在现有列表中，则添加
            if (!existingIds.has(video.id)) {
              recommendList.value.push(video);
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // 检查是否有更多数据

    page.value++; // 只在没有找到 luma 的情况下增加页码
  }

  isFetching.value = false; // 所有请求完成，解锁
};

// 自动加载前3页的数据
const loadInitialData = async () => {
  await getRecommend(); // 只需调用一次以开始加载
};

// 组件挂载时加载初始数据和设置滚动事件监听
onMounted(() => {
  loadInitialData();
  getdrawNumdeplete();
});

//运镜

const movementRange = {
  min: -75,
  max: 75,
  step: 0.1,
};

const focusRectangleStyle = computed(() => {
  let transform = "";
  switch (movementType.value) {
    case "水平运镜":
      transform = `translateX(${-movementValue.value}%)`;
      break;
    case "垂直运镜":
      transform = `translateY(${-movementValue.value}%)`;
      break;
    case "拉远/推进":
      const scale = 1 / (1 + movementValue.value / 100);
      transform = `scale(${scale})`;
      break;
    case "垂直摇镜":
      transform = `perspective(1000px) rotateX(${movementValue.value}deg)`;
      break;
    case "水平摇镜":
      transform = `perspective(1000px) rotateY(${-movementValue.value}deg)`;
      break;
    case "旋转运镜":
      transform = `rotate(${movementValue.value}deg)`;
      break;
  }
  return { transform };
});

const resetMovement = () => {
  movementValue.value = 0;
};

const determine = () => {
  outerVisible3.value = false;
  console.log("确定运镜方式:", movementType.value, movementValue.value);
  prompt.value =
    prompt.value +
    "    " +
    `运镜方式：${movementType.value}，运镜值：${movementValue.value}`;
};

//滑块
const options = ["高性能", "高表现"];

const descriptions = ref({
  小猫: "这是一段关于小猫的描述",
  小狗: "这是一段关于小狗的描述",
  小鸡: "这是一段关于小鸡的描述",
  小鸭: "这是一段关于小鸭的描述",
  小鸟: "这是一段关于小鸟的描述",
});

const setText = (key) => {
  prompt.value = descriptions.value[key];
};

//视频
// const recommendList = [
//   {
//     title: "特写镜头，蟾蜍视频",
//     toadVideo: require('../../assets/work/1.mp4'),
//     id: 1
//   },
//   {
//     title: "12312",
//     toadVideo: require('../../assets/work/2.mp4'),
//     id: 2
//   },
//   {
//     title: "特写镜头，蟾蜍视频12asd312",
//     toadVideo: require('../../assets/work/3.mp4'),
//     id: 3
//   },
//   {
//     title: "特写镜头，蟾蜍视频12asda3",
//     toadVideo: require('../../assets/work/4.mp4'),
//     id: 4
//   },
//   {
//     title: "特写镜头，蟾蜍视频123131313",
//     toadVideo: require('../../assets/work/5.mp4'),
//     id: 5
//   },
//   {
//     title: "特写镜头5684456456",
//     toadVideo: require('../../assets/work/6.mp4'),
//     id: 6
//   },
// ]

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};
const formatTime2 = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const playVideo = (index) => {
  if (videoRefs.value[index]) {
    videoRefs.value[index].play();
  }
};
const playVideo1 = (index) => {
  if (videoRefs1.value[index]) {
    videoRefs1.value[index].play();
  }
};
const playVideo2 = (el) => {
  if (el) {
    el.play();
  }
};

const pauseVideo = (index) => {
  if (videoRefs.value[index]) {
    videoRefs.value[index].pause();
  }
};
const pauseVideo1 = (index) => {
  if (videoRefs1.value[index]) {
    videoRefs1.value[index].pause();
  }
};
const pauseVideo2 = (el) => {
  if (el) {
    el.pause();
  }
};

const updateProgress = (index) => {
  if (videoRefs.value[index]) {
    currentTimes.value[index] = videoRefs.value[index].currentTime;
  }
};

const updateDuration = (index) => {
  if (videoRefs.value[index]) {
    durations.value[index] = videoRefs.value[index].duration;
  }
};
const updateProgress1 = (index) => {
  if (videoRefs1.value[index]) {
    currentTimes.value[index] = videoRefs1.value[index].currentTime;
  }
};

const updateDuration1 = (index) => {
  if (videoRefs1.value[index]) {
    durations.value[index] = videoRefs1.value[index].duration;
  }
};
const updateProgress2 = (index) => {
  if (videoRefs2.value[index]) {
    currentTimes2.value[index] = videoRefs2.value[index].currentTime;
  }
};

const updateDuration2 = (index) => {
  if (videoRefs2.value[index]) {
    durations2.value[index] = videoRefs2.value[index].duration;
  }
};

const openModal = (toadVideo) => {
  isModalOpen.value = true;
  currentModalVideo.value = toadVideo;
};
const openModal1 = (videopath_ol) => {
  console.log(videopath_ol);
  isModalOpen.value = true;
  currentModalVideo.value = videopath_ol;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateModalProgress = () => {
  // Implement if needed
};

onMounted(() => {
  videoRefs.value = new Array(recommendList.length);
  videoRefs1.value = new Array(recommendList.length);
  videoRefs2.value = new Array(musicUrl.value.length);
  currentTimes.value = new Array(recommendList.length).fill(0);
  currentTimes2.value = new Array(recommendList.length).fill(0);
  durations.value = new Array(recommendList.length).fill(0);
  durations2.value = new Array(recommendList.length).fill(0);
});

//复制
const copy = (index) => {
  console.log("推荐列表:", recommendList.value[index].title);
  console.log("当前索引:", index);
  prompt.value = recommendList.value[index].title;
  ElMessage({ type: "success", message: "复制成功" });
};
const copy1 = (index) => {
  console.log("当前索引:", index);
  prompt.value = musicUrl.value[index].prompt;
  ElMessage({ type: "success", message: "复制成功" });
};

//重绘
const retryVideo = () => {};

//加四秒
const addFourSeconds = () => {};

//选择器
const items = ref([
  { id: 1, name: "1条", selected: false },
  { id: 2, name: "2条", selected: false },
  { id: 3, name: "3条", selected: false },
]);

const selectedItemId = ref(null);

const selectItem = (item) => {
  items.value.forEach((i) => (i.selected = false));
  item.selected = true;
  selectedItemId.value = item.id;
};

onMounted(() => {
  if (items.value.length > 0) {
    selectItem(items.value[0]);
  }
});

const activeTab = ref("recommend"); // 默认选中的选项卡名称
//加载

const openFullScreen = () => {
  if (prompt.value.length > 0 && imageUrl.value !== null) {
    const loading = ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    setTimeout(() => {
      loading.close();
    }, 5000);
    activeTab.value = "myvideo"; // 设置为所需的选项卡名称
  }
};

//滑块
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  initialValue: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(["update:modelValue"]);

const localValue = ref(props.modelValue);

onMounted(() => {
  if (props.initialValue !== null) {
    localValue.value = Math.min(
      Math.max(props.initialValue, props.min),
      props.max
    );
    emit("update:modelValue", localValue.value);
  }
  getOpenid();
  getUserInfo();
});

watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue;
  }
);

const updateValue = () => {
  emit("update:modelValue", Number(localValue.value));
};

const sliderBackground = computed(() => {
  const percentage =
    ((localValue.value - props.min) / (props.max - props.min)) * 100;
  return `linear-gradient(to right, #10B981 0%, #3B82F6 ${percentage}%, #e2e8f0 ${percentage}%, #e2e8f0 100%)`;
});

const onImageUploaded = (url) => {
  imageUrl.value = url; // 更新图片 URL
};
//axios请求



//获取后台的积分消耗数值

const drawNumdeplete = ref(0);
 const getdrawNumdeplete = async () => {
   const res = await axios.get("/genceai/?s=/api/api/run/m/xz_aivideo/do/config/uniacid/10005",{
    type:3,
    openid:openid.value,
   })
   if (res.data.message === "获取数据成功") {
    console.log("积分消耗：",res);
    
     drawNumdeplete.value = res.data.data.money_b;
   } else {
     console.error("获取数据失败");
     }

     }



const openid = ref("");
const user = reactive({
  username: "",
  openid: "",
  password: "",
  drawNum: "",
});
const id = ref(0);
//获取用户信息
const getOpenid = () => {
  user.value = JSON.parse(window.localStorage.getItem("user"));
  console.log(user.value);
  openid.value = user.value.openid;
};

//获取用户信息接口
const getUserInfo = () => {
  axios
    .get(
      "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
        openid.value
    )
    .then((res) => {
      console.log(res.data.data);
      id.value = res.data.data.id;
      console.log("用户id:", id.value);
      drawNum.value = res.data.data.drawNum;
    })
    .catch((err) => {
      console.log(err);
    });
};

//获取用户作品接口

const musicUrl = ref([]); // 定义为响应式变量
let currentPage = ref(1); // 当前页码
const isLoading = ref(false); // 标记加载状态
const requestedMusicIds = new Set(); // 用于存储已请求的 musicid
let isB = false;
let isD = false;
//做定义一个变量数组用来存储绘画中的订单
const deng = ref([]);
const getUserVideoList = async () => {
  // 先清空音乐列表
  const videoUrl = ref([]);

  try {
    console.log("CurrentPage:", currentPage.value);

    //获取用户的订单列表（需要传递openid和page参数，一次请求6条数据，数据里面包含musicid，通过musicid获取视频url信息,state=1表示绘画完成，=0表示正在绘画，正在绘画的时候没有视频url）
    const response = await axios.get(
      `/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/orderlist/uniacid/10005&openid=${openid.value}&page=${currentPage.value}`
    );
    console.log(response.data.data);
    const videoList = response.data.data;

    for (let item of videoList) {
      if (item.state == 0) {
        isB = true;
      }
    }

    console.log("imgurl:", imageUrl.value);

    for (let item of videoList) {
      //1.每次进入默认查询的时候 做一个临时数据 记录有几个绘画中任务 然后生成多个加载框 绘画结束一个 如何去掉一个
      if (item.state == 0) {
        //2.现在默认page是1 不++ 需要触底 page++然后新数据合并数组动态渲染
        if (!deng.value.some((existingItem) => existingItem.id === item.id)) {
          // 假设 item 有 id 属性
          deng.value.push(item);
          console.log("绘画中的订单:", deng.value);
        }
      } else if (item.state === 1) {
        // 状态为 1 的订单，表示绘画已经完成
        // 从 deng 中移除该订单
        console.log("#############", isB);
        deng.value = deng.value.filter((existingItem) => {
          if (existingItem.id === item.id) {
            if (isB) {
              isD = true;
            }
            return false;
          }
          return true;
        });
        console.log("绘画已完成，更新后的订单:", deng.value);
      }
    }
    console.log("绘画中的订单：", deng.value);
    console.log("绘画完成的视频url", musicUrl.value);
    console.log("绘画中的订单数量：", deng.value.length);

    // 处理视频数据

    for (const video of videoList) {
      console.log("videoid:", video.musicid);
      console.log("requestedMusicIds:", requestedMusicIds);
      // 检查当前 musicid 是否已经请求过
      if (!requestedMusicIds.has(video.musicid) && video.state == 1) {
        requestedMusicIds.add(video.musicid); // 将当前 musicid 添加到集合中
        //通过musicid获取视频url信息
        const musicResponse = await axios.get(
          "/genceai/?s=/api/api/run/m/xz_aivideo/do/showmusic/uniacid/10005/&id=" +
            video.musicid
        );
        const videoInfo = {
          id: id.value,
          videopath_ol: musicResponse.data.data.videopath_ol,
          title: musicResponse.data.data.title,
          userid: musicResponse.data.data.userid,
          prompt: musicResponse.data.data.orderobj.prompt.slice(1),
          orderid: musicResponse.data.data.orderobj.id,
        };
        console.log(videoInfo);
        // 检查是否已经存在于 musicUrl 中
        console.log("ISD", isD);
        if (isD) {
          console.log(111111111111111111);

          musicUrl.value = [];
          requestedMusicIds.clear();
          currentPage.value = 1;
          isD = false;
          isB = false;
          getUserVideoList();
          return;
        } else {
          musicUrl.value.push(videoInfo);

          console.log("videoInfo:", videoInfo);
        }
      }
    }
    console.log("musicUrl:", musicUrl.value);

    for (let item of videoList) {
      if (item.state == 0) {
        setTimeout(async () => {
          await getUserVideoList();
        }, 5000);
        return;
      }
    }

    console.log(currentPage.value);
    getUserInfo();
  } catch (error) {
    console.log(error);
  }
  return {
    deng,
    getUserVideoList,
  };
};

// 初始化加载用户视频列表
onMounted(() => {
  loadMore(); // 初次加载时加载第一页数据
});
const videoScrollContainer = ref(null); // 用于引用视频容器
const hasMoreData = ref(true); // 是否还有更多数据可加载
const previousMusicUrlLength = ref(0); // 用于存储上一次请求时 musicUrl 的长度

const loadMore = async () => {
  if (isLoading.value) return; // 防止重复加载
  isLoading.value = true; // 标记为正在加载
  ElMessage({ type: "success", message: "加载视频中，请稍等！" });

  // 保存加载前的 musicUrl 长度
  previousMusicUrlLength.value = musicUrl.value.length;

  try {
    const response = await getUserVideoList(); // 请求用户视频列表

    // 根据返回数据设置是否有更多数据
    if (response.data && response.data.length < 6) {
      // 假设每次请求返回6条数据
      hasMoreData.value = false; // 如果返回的数据少于6条，则表示没有更多数据
    }

    // 如果 musicUrl 的长度没有变化，则退回到上一个页码
    if (musicUrl.value.length === previousMusicUrlLength.value) {
      currentPage.value = Math.max(currentPage.value - 1, 1); // 确保页码不小于1
      hasMoreData.value = false; // 停止增加页码
      ElMessage({ type: "success", message: "视频全部加载完成！" });
    }
  } catch (error) {
    console.error("加载视频失败:", error);
    ElMessage({ type: "error", message: "加载视频失败，请稍后再试。" });
  } finally {
    isLoading.value = false; // 请求完成后重置加载状态
  }
};

// 监听图像容器的滚动事件
const handleScroll = () => {
  if (!videoScrollContainer.value) return; // 防止访问 null

  const { scrollTop, scrollHeight, clientHeight } = videoScrollContainer.value;

  // 检测是否滚动到底部
  if (
    scrollTop + clientHeight >= scrollHeight - 5 &&
    !isLoading.value &&
    hasMoreData.value
  ) {
    // 增加页码
    currentPage.value += 1;
    loadMore(); // 加载更多视频
  }
};

// 初始化时监听容器的滚动事件
onMounted(() => {
  if (videoScrollContainer.value) {
    videoScrollContainer.value.addEventListener("scroll", handleScroll);
  }
});

// 组件卸载时移除监听器
onBeforeUnmount(() => {
  if (videoScrollContainer.value) {
    videoScrollContainer.value.removeEventListener("scroll", handleScroll);
  }
});
//删除视频接口

const deleteVideo = (orderid) => {
  //删除确认的弹窗
  ElMessageBox.confirm("确认删除该视频吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      console.log("删除视频的orderid:", orderid);
      axios
        .post(
          "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/delorder/uniacid/10005&openid=" +
            openid.value +
            "&orderid=" +
            orderid
        )
        .then((res) => {
          console.log(res);
          if (res.status
          === 200) {
            ElMessage({ type: "success", message: "删除成功" });
            getUserVideoList(); // 重新获取视频列表
            //刷新页面
            location.reload();
          } else {
            ElMessage({ type: "error", message: "删除失败" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch(() => {});
};

//生成视频接口
console.log("描述词：", prompt.value);
let nowsize = 0;
const add = () => {
  console.log("描述词：", prompt.value);
  const music = musicUrl.value.length;
  console.log("music:", music);
  const payload = {
    openid: openid.value,
    prompt: prompt.value,
    musicimg: imageUrl.value,
    aitype: 2,
    atype: "luma",
    engine: "runway",
  };
  console.log("imageUrl.value:", imageUrl.value);

  if (prompt.value.length > 0 && imageUrl.value !== null) {
    axios
      .post(
        `/genceai/?s=/api/api/run/m/xz_aivideo/do/showaivideo/uniacid/10005`,
        payload
      )
      .then((res) => {
        console.log(res);
        if (res.data.message === "success") {
          console.log("music:", music);
          getUserVideoList(); // 调用获取视频列表的方法
          getUserInfo();
          ElMessage({ type: "success", message: res.data.data });
        } else {
          ElMessage({ type: "error", message: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
        ElMessage({ type: "error", message: "请求失败" });
      });
  } else {
    ElMessage({ type: "error", message: "请输入描述词或上传图片" });
  }
};

const username = ref("");
const password = ref("");
const drawNum = ref(0);
const userid= ref(0);
const showSuspend = ref(false); // 控制suspend盒子的显示
const isLoggedIn = ref(false); // 初始化为未登录状态


const getUserinfo = () => {
  // 获取用户信息
  axios
    .get(
      "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10005&openid=" +
        openid.value
    )
    .then((res) => {
      console.log(res.data.data);
      drawNum.value = res.data.data.drawNum;
      userid.value = res.data.data.id;
      console.log(drawNum.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const handleMouseEnter = () => {
  showSuspend.value = true;
};
const handleMouseLeave = () => {
  showSuspend.value = false;
};
const handleLogout = () => {
  isLoggedIn.value = false; // 设置未登录状态
  username.value = ""; // 清空用户名
  password.value = ""; // 清空密码
  userid.value = ""; // 清空用户id
  window.localStorage.removeItem("user"); // 清除存储的用户数据
  ElMessage({
    message: "已成功退出登录",
    type: "success",
  });
};

const handleLoginClick = () => {
  if (!isLoggedIn.value) {
    outerVisible2.value = true; // 只有在未登录状态下才打开弹窗
  }
};

onMounted(() => {
  const userData = JSON.parse(window.localStorage.getItem("user"));
  console.log("userData:", userData);
  if (userData) {
    isLoggedIn.value = true; // 设置为已登录状态
    username.value = userData.username; // 可以选择展示用户名等信息
    openid.value = userData.openid; // 获取openid // 这里可以调用获取用户信息的函数
    drawNum.value = userData.drawNum; // 获取积分
    getUserinfo(); // 获取用户信息
  } else {
    // 未登录状态
    isLoggedIn.value = false;
  }
});
const openwork = () => {
  ElMessage({
    message: "此功能近期上线，敬请期待！",
    type: "error",
  });
};

</script>
  <style lang="less" scoped>
// @import "./css/variables.css";

.home {
  width: 100vw;
}

.dialogBox-body {
  position: relative;
  width: 100%;
  border-radius: 8px;
  // background-color: #000000d8;
  color: #b4b9bf;
  margin: -60px auto;
}

.dialogBox-body1 {
  position: relative;
  width: 56%;
  border-radius: 8px;
  // background-color: #000000d8;
  color: #b4b9bf;
  top: 10px;
  margin: 0 auto;
}

.dialogBox-body-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.page-content-wrapper {
  margin: 0 auto;
}

.page-content-wrapper .big-card {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
  height: 70vh;
  overflow: scroll;
}

.page-content-wrapper .big-card-shu {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
  height: 70vh;
  overflow: scroll;
}

.page-content-wrapper .big-card::-webkit-scrollbar {
  display: none;
}

.page-content-wrapper .big-card-shu::-webkit-scrollbar {
  display: none;
}

.page-content-wrapper .big-card-shu {
  text-align: center;
}

.input[type="text"] {
  display: block;
  color: rgb(255, 255, 255);
  background: linear-gradient(
    142.99deg,
    rgba(84, 84, 84, 0.742) 15.53%,
    rgba(69, 69, 69, 0.63) 88.19%
  );
  box-shadow: 0px 12px 24px -1px rgba(177, 177, 177, 0.18);
  border-color: rgba(7, 4, 14, 0);
  border-radius: 20px;
  block-size: 20px;
  padding: 18px 15px;
  outline: none;
  text-align: center;
  width: 700px;
  transition: 0.5s;
}

.input[type="text"]:hover {
  width: 710px;
}

.input[type="text"]:focus {
  width: 720px;
}

.dialogBox-body .dialogBox-body-content button {
  border: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 40px;
  border-radius: 50px;
  color: white;
  outline: none;
  position: relative;
  cursor: pointer;
}

.but {
  // background-image: linear-gradient(to right, #25aae1, #40e495);
  // box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);#0f3460#e94560
  background-image: linear-gradient(to right, #0246ac 0%, #d43f8d 100%);
}

.preview_box img {
  width: 200px;
}

.img-box button {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border: none;
  color: #f00;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.img-box {
  position: relative;
  display: inline-block;
  top: -100;
  display: flex;
  width: 100px;
  margin-left: 25%;
}

.img-box img {
  width: 100px;
  height: 100%;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
}

.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
}

/deep/.el-tabs__nav-scroll {
  background-color: #000000;
}

/deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #f5f5dcde !important;
  border-left-color: #f5f5dc !important;
  border-right-color: #f5f5dc !important;
  color: #000000 !important;
  border-radius: 20px !important;
}

/deep/.el-tabs__item:hover {
  color: #fff !important;
}

.gaoji {
  width: 80px;
  height: 30px;
  line-height: 30px;
  background-color: #00000000;
  border-radius: 15px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 7px;
  margin-top: 5px;
  color: #fff;
  border: none;
  float: left;
}

.gaoji1 {
  height: 20px;
  text-align: center;
  margin-top: 10px;
  float: left;
}

.el-divider--vertical {
  border-left: 1.5px #7f7f7f7d var(--el-border-style);
}

.tishiyu {
  height: 30px;
  line-height: 30px;
  background-color: #00000000;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  border: none;
}

.select {
  height: 30px;
  line-height: 30px;
  // background-color: #000000;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 5px;
  color: #fff;
  border: none;
  float: left;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: -10px;
}

.select .a1 {
  color: #999;
  margin-left: -10px;
  margin-top: 2px;
}

.dialogBox-body1 .a1 {
  font-size: 14px;
  color: #fff;
}

.select .a2 {
  margin-left: 10px;
  cursor: pointer;
}

.gaoji:hover {
  background-color: #00000000;
  color: #fff;
}

.tishiyu:hover {
  background-color: #00000000;
  color: #fff;
}

.yun {
  display: flex;
  align-items: center;
  margin-left: 10px;
  float: right;
  font-size: 14px;
  margin-top: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.yun .a1 {
  margin-left: 5px;
  margin-right: 10px;
}

.bang {
  float: right;
  margin-top: -2px;
  margin-right: 200px;
  cursor: pointer;
}

.select .a2 {
  margin-left: 10px;
  cursor: pointer;
}

.dilaog-box {
  width: 100%;
  // background-color: #000000;
}

.dilaog-box .text-lg {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.dilaog-box .flex {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;
}

.dilaog-box .el-segmented {
  width: 180px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  margin-left: 20px;
}

.el-segmented {
  --el-segmented-color: var(--el-text-color-regular);
  --el-segmented-bg-color: var(--el-fill-color-light);
  --el-segmented-padding: 2px;
  --el-segmented-item-selected-color: #000000 !important;
  --el-segmented-item-selected-bg-color: #f5f5dc !important;
  --el-segmented-item-selected-disabled-bg-color: var(
    --el-color-primary-light-5
  );
  --el-segmented-item-hover-color: var(--el-text-color-primary);
  --el-segmented-item-hover-bg-color: var(--el-fill-color-dark);
  --el-segmented-item-active-bg-color: var(--el-fill-color-darker);
  --el-segmented-item-disabled-color: var(--el-text-color-placeholder);
}

.slider-container {
  width: 100%;
  max-width: 280px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.slider-wrapper {
  margin-top: 20px;
  flex-grow: 1;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bf40bf, #00bfff);
  cursor: pointer;
  box-shadow: 0 0 0 3px #000, 0 0 0 6px rgba(191, 64, 191, 0.1);
  transition: box-shadow 0.2s;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bf40bf, #00bfff);
  cursor: pointer;
  box-shadow: 0 0 0 3px #000, 0 0 0 6px rgba(191, 64, 191, 0.1);
  transition: box-shadow 0.2s;
}

.slider::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 3px #000, 0 0 0 6px rgba(191, 64, 191, 0.2);
}

.slider::-moz-range-thumb:hover {
  box-shadow: 0 0 0 3px #000, 0 0 0 6px rgba(191, 64, 191, 0.2);
}

.value-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.initial-value {
  font-size: 12px;
  color: #888;
  margin-bottom: 2px;
}

.current-value {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #bf40bf, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

//参数设置
button[data-v-fae5bece]:before {
  content: "";
  display: block;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.4) 50%
  );
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 1%;
  // position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50px;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.range {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 10px;
  margin-top: 30px;
}

.dilaog-box[data-v-fae5bece] {
  width: 90%;
  height: 400px;
  margin: 0 auto;
}

.box-wai {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 10px;
  margin-top: 20px;
}

.box-wai .box {
  width: 100%;
}

.drawer-content {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.drawer-content img {
  margin-left: 20px;
  margin-top: 20px;
}

.upload-container {
  margin-left: 10px;
  margin-top: 20px;
}

.aout-container {
  margin-top: 20px;
  margin-left: 10px;
}
.aout-container .text-lg1 {
  margin-left: 10px;
  font-size: 20px;
}

.aout-container1 {
  margin-top: 30px;
  margin-left: 10px;
}
.aout-container1 .text-lg1 {
  margin-left: 10px;
  font-size: 20px;
}
.button-dialog {
  background-image: linear-gradient(to right, #25aae1, #40e495);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  background-image: linear-gradient(to right, #0246ac 0%, #d43f8d 100%);
  color: #fff;
  border: none;
  width: 100px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}

.big-card .video-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 95%;
  margin: 0 auto;
  /* 16:9 aspect ratio */
}

.big-card-shu .video-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 95%;
  margin: 0 auto;
  /* 16:9 aspect ratio */
}

.big-card .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
}

.big-card-shu .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
}

.time-display {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px 6px;
  border-radius: 4px;
}

.fullscreen-icon {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.controls {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  background-color: #2a2a2a;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.big-card button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2a2a;
  color: white;
  border: none;
  padding: 10px 12px;
  margin-left: -10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.big-card-shu button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2a2a;
  color: white;
  border: none;
  padding: 10px 12px;
  margin-left: -10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.big-card button:nth-child(1) {
  margin-left: 10px;
}

.big-card button .a1 {
  transition: transform 0.3s;
  margin-right: 10px;
}

.big-card button:hover .a1 {
  transform: translate(0%, -5%) scale(1.1);
}

.big-card-shu button:nth-child(1) {
  margin-left: 10px;
}

.big-card-shu button .a1 {
  transition: transform 0.3s;
  margin-right: 10px;
}

.big-card-shu button:hover .a1 {
  transform: translate(0%, -5%) scale(1.1);
}

.w-5 {
  width: 20px;
}

.h-5 {
  height: 20px;
}

.w-6 {
  width: 34px;
}

.h-6 {
  height: 34px;
}

.video-info {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.copy {
  position: relative;
  background-color: #000000;
  width: 80%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
  transition: background-color 0.4s;
}

.copy:hover .copy-te {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy .copy-te {
  display: none;
  width: 100%;
  height: 35px;
  cursor: pointer;
  position: absolute;
  text-align: center;
  background-color: #000000c1;
  color: white;
  border-radius: 5px;
  margin-top: 20px;
}

.copy .copy-a1 {
  font-size: 14px;
}

.copy .copy-a2 {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.video-info .copy-a3 {
  margin-top: 10px;
}

.big-card .video-container {
  width: 450px;
  position: relative;
  max-width: 36rem;
  overflow: hidden;
  background-color: #000000;
  border-radius: 12px;
  overflow: hidden;
  float: left;
  margin-left: 2%;
  margin-bottom: 20px;
  height: 360px;
  transition: background-color 0.4s;
}

.big-card .video-container1 {
  width: 450px;
  position: relative;
  max-width: 36rem;
  overflow: hidden;
  background-color: #000000;
  border-radius: 12px;
  overflow: hidden;
  float: left;
  margin-left: 2%;
  margin-bottom: 20px;
  height: 360px;
  transition: background-color 0.4s;
}

.big-card-shu .video-container {
  width: 850px;
  position: relative;
  max-width: 36rem;
  overflow: hidden;
  background-color: #000000;
  border-radius: 12px;
  overflow: hidden;
  margin: 30px auto;
  height: 430px;
  transition: background-color 0.4s;
}

.big-card .video-container:hover,
.big-card .video-container:hover .copy {
  background-color: #1a1a1a;
}

.big-card .video-container1:hover,
.big-card .video-container1:hover .copy {
  background-color: #1a1a1a;
}

.big-card-shu .video-container:hover,
.big-card-shu .video-container:hover .copy {
  background-color: #1a1a1a;
}

button[data-v-70c3fd52]:before {
  content: "";
  display: block;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.4) 50%
  );
  background-size: 210% 100%;
  background-position: right bottom;
  height: 0%;
  width: 0%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50px;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.top-controls,
.bottom-controls {
  position: absolute;
  display: flex;
  gap: 0.4rem;
  opacity: 0;
  transition: all 0.3s ease;
}

.top-controls {
  top: 0.5rem;
  right: 0.5rem;
  transform: translateY(-10px);
}

.bottom-controls {
  bottom: 100px;
  left: 20px;
  right: 20px;
  justify-content: space-between;
  transform: translateY(10px);
}

.video-container:hover .top-controls,
.video-container:hover .bottom-controls {
  opacity: 1;
  transform: translateY(0);
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 2rem;
  width: 40px;
  height: 2rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  // color: rgb(222, 28, 28);
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
  text-align: center;
  backdrop-filter: blur(4px);
}

.icon-button:hover {
  background-color: white;
  color: black;
}

.duration {
  font-size: 12px;
  // background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: 40px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  // padding: 0.2rem 0.5rem;
  // border-radius: 9999px;
  // backdrop-filter: blur(4px);
}

.expand {
  margin-top: 30px;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* background-color: rgba(255, 255, 255, 0.3); */
}

.progress {
  height: 100%;
  /* background-color: white; */
  /* transition: width 0.3s ease-out; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 1000px;
  background-color: #000;
  border-radius: 0.5rem;
  overflow: hidden;
}

.modal-content video {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.close-button svg {
  width: 1.5rem;
  height: 1.5rem;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

.video-container:hover .icon-button {
  animation: pulse 2s infinite;
}

//视频绘画中
.video-editing-container {
  width: 92%;
  height: 94%;
  margin: 10px auto;
  background-color: #1a1a2e;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.video-frame {
  width: 100%;
  height: 60%;
  background-color: #16213e;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.video-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.scene {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/work/bg.jpg?height=180&width=260");
  background-size: cover;
  animation: zoomInOut 10s infinite alternate;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
}

.progress-bar1 {
  width: 100%;
  height: 5px;
  background-color: #0f3460;
  position: absolute;
  bottom: 0;
}

.progress1 {
  width: 0;
  height: 100%;
  background-color: #e94560;
  animation: progress 5s linear infinite;
}

.tools {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.tool-icon {
  width: 40px;
  height: 40px;
  background-color: #0f3460;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e94560;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tool-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(233, 69, 96, 0.7);
}

.tool-icon svg {
  width: 24px;
  height: 24px;
}

.loading-text {
  color: #e94560;
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  animation: pulse 2s infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}

.cut {
  animation: wobble 3s infinite;
}

.effects {
  animation: rotate 4s linear infinite;
}

.audio {
  animation: bounce 2s infinite;
}

@keyframes wobble {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px) rotate(-5deg);
  }

  75% {
    transform: translateX(5px) rotate(5deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

//运镜

.camera-movement-control {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #e0e0e0;
  /* background-color: #1e2a38; */
  padding: 20px;
  border-radius: 8px;
}

.preview-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  /* background-color: #2c3e50; */
  position: relative;
  border-radius: 4px;
}

.preview-image {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/work/bg.jpg");
  background-size: cover;
  position: relative;
}

.focus-rectangle {
  position: absolute;
  top: 30%;
  left: 40%;
  width: 100px;
  height: 100px;
  border: 2px solid #00ffff;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.controls1 {
  margin-top: 20px;
}

.movement-type,
.movement-slider {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.movement-type label,
.movement-type select,
.movement-type button {
  margin-right: 10px;
  cursor: pointer;
}

.movement-type select,
.movement-type button,
.movement-slider input[type="number"] {
  background-color: #34495e;
  color: #e0e0e0;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}

.movement-slider input[type="range"] {
  flex-grow: 1;
  margin-right: 10px;
  background-color: #34495e;
}

.movement-slider input[type="number"] {
  width: 70px;
}

.movement-slider button {
  cursor: pointer;
  background-color: #3498db;
}

.movement-slider button:hover {
  background-color: #2980b9;
}

.right-header {
  width: 100%;
  height: 60px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbox {
  width: 460px;
  height: 100%;
  display: flex;
  float: right;
  align-items: center;
}

.join-group {
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-group a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark-site {
  width: 87px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.invite-users {
  width: 134px;
  height: 24px;
  border-radius: 4px;
  background: -webkit-linear-gradient(90deg, #d357fe, #be38f3, #7a219e);
  background: linear-gradient(90deg, #d357fe, #be38f3, #7a219e);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.invite {
  width: 100%;
  margin: 0 auto;
  background-color: #1a202c;
  color: #e2e8f0;
  padding: 2rem;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #a78bfa;
}

.description {
  margin-bottom: 1rem;
  margin-top: 40px;
}

.benefits-list {
  list-style-type: decimal;
  list-style-position: inside;
  margin-bottom: 1.5rem;
}

.benefits-list li {
  margin-bottom: 0.5rem;
}

.highlight {
  color: #a78bfa;
}

.cashout {
  color: #fbbf24;
  cursor: pointer;
}

.cashout:hover {
  text-decoration: underline;
}

.referral-input {
  display: flex;
  margin-bottom: 1.5rem;
  background-color: #2d3748;
  border-radius: 0.375rem;
  overflow: hidden;
}

.referral-input input {
  flex-grow: 1;
  background-color: #4a5568;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
}

.referral-input button {
  background-color: #4a5568;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.referral-input button:hover {
  background-color: #2d3748;
}

.user-table {
  background-color: #2d3748;
  border-radius: 0.375rem;
  padding: 1rem;
  height: 463px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.registered-users {
  color: #60a5fa;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.75rem 1rem;
  text-align: left;
}

th {
  border-bottom: 1px solid #4a5568;
}

.no-data {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* 添加这一行来垂直居中 */
  padding: 2rem 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  background-color: #4a5568;
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.pagination button.active {
  background-color: #60a5fa;
}

.pagination select {
  background-color: #4a5568;
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
}

.login {
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: right;
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.logged-in {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.not-login {
  font-size: 14px;
  width: 80px;
  height: 36px;
  border-radius: 20px;
  line-height: 36px;
  color: #ffffff;
  background: -webkit-linear-gradient(90deg, #2c9ca4, #27c7ad, #75ef9c);
  background: linear-gradient(90deg, #2c9ca4, #27c7ad, #75ef9c);
  text-align: center;
}

.not-login:hover {
  background: -webkit-linear-gradient(90deg, #1a7b7f, #23a69b, #62df85);
  /* 悬停时背景颜色变化 */
  background: linear-gradient(90deg, #1a7b7f, #23a69b, #62df85);
  transform: scale(1.05);
  /* 悬停时轻微放大 */
}

.custom-login-dialog {
  width: 700px;
  max-width: 700px;
  /* 确保对话框最大宽度 */
  height: 440px;
  max-height: 440px;
  /* 确保对话框最大高度 */
  margin: 0;
  padding: 0;
  background-color: #000;
}

.suspend {
  width: 340px;
  float: right;
  background: #bdc3c7;
  background: -webkit-linear-gradient(
    to top,
    rgba(189, 199, 193, 0.6),
    rgba(32, 43, 55, 0.9)
  );
  background: linear-gradient(
    to right top,
    rgba(231, 244, 237, 0.6),
    rgba(32, 43, 55, 0.8),
    rgba(32, 43, 55, 0.9)
  );
  position: fixed;
  top: 62px;
  /* 根据需要调整 */
  right: 20px;
  /* 根据需要调整 */
  z-index: 9999;
  border-radius: 10px;
}

.suspend-head {
  display: flex;
  align-items: center;
  padding: 10px;
}

.suspend-head img {
  width: 50px;
  /* 图片宽度 */
  height: 50px;
  /* 图片高度 */
  border-radius: 50%;
  /* 圆形图片 */
  margin-right: 10px;
  /* 图片与文字间距 */
}

.suspend-head span {
  color: #e8e8e8;
  /* 字体颜色 */
  font-size: 12px;
  /* 字体大小 */
}

.suspend-head a {
  /* 个人资料链接样式 */
  color: #cccccc;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  text-decoration: none;
}

.suspend-line {
  width: 90%;
  height: 1px;
  background-color: #cccccc;
  margin: 0 auto;
  margin-top: 10px;
}

.integral {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #cccccc;
  /* 字体颜色 */
  font-size: 14px;
  /* 字体大小 */
}

.integral div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.integral div span:first-child {
  display: block;
  font-size: 14px;
  /* 积分数字的字体大小 */
  color: #ffffff;
}

.integral div span:last-child {
  font-size: 14px;
  /* 描述文字的字体大小 */
  color: #cccccc;
}


/* 头部 */
.box-header {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
}
.back {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back img {
  width: 24px;
  height: 20px;
}
.header-line {
  width: 1px;
  height: 30px;
  background-color: #333;
  margin-left: 20px;
}
.logo {
  width: 110px;
  height: 40px;
  margin-left: 20px;
}
.logo img {
  width: 100%;
  height: 100%;
}
/* 登录 */
.login {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: auto;
  cursor: pointer;
  margin-right: 60px;
}

.logged-in {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.not-login {
  font-size: 14px;
  width: 80px;
  height: 36px;
  border-radius: 20px;
  line-height: 36px;
  color: #ffffff;
  background: -webkit-linear-gradient(90deg, #2c9ca4, #27c7ad, #75ef9c);
  background: linear-gradient(90deg, #2c9ca4, #27c7ad, #75ef9c);
  text-align: center;
}

.not-login:hover {
  background: -webkit-linear-gradient(90deg, #1a7b7f, #23a69b, #62df85);
  /* 悬停时背景颜色变化 */
  background: linear-gradient(90deg, #1a7b7f, #23a69b, #62df85);
  transform: scale(1.05);
  /* 悬停时轻微放大 */
}

.custom-login-dialog {
  width: 700px;
  max-width: 700px;
  /* 确保对话框最大宽度 */
  height: 440px;
  max-height: 440px;
  /* 确保对话框最大高度 */
  margin: 0;
  padding: 0;
  background-color: #000;
}

.suspend {
  width: 340px;
  float: right;
  background: #bdc3c7;
  background: -webkit-linear-gradient(
    to top,
    rgba(189, 199, 193, 0.6),
    rgba(32, 43, 55, 0.9)
  );
  background: linear-gradient(
    to right top,
    rgba(231, 244, 237, 0.6),
    rgba(32, 43, 55, 0.8),
    rgba(32, 43, 55, 0.9)
  );
  position: fixed;
  top: 50px;
  /* 根据需要调整 */
  right: 20px;
  /* 根据需要调整 */
  z-index: 9999;
  border-radius: 10px;
}

.suspend-head {
  display: flex;
  align-items: center;
  padding: 10px;
}

.suspend-head img {
  width: 50px;
  /* 图片宽度 */
  height: 50px;
  /* 图片高度 */
  border-radius: 50%;
  /* 圆形图片 */
  margin-right: 10px;
  /* 图片与文字间距 */
}

.suspend-head span {
  color: #e8e8e8;
  /* 字体颜色 */
  font-size: 12px;
  /* 字体大小 */
}

.suspend-head a {
  /* 个人资料链接样式 */
  color: #cccccc;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  text-decoration: none;
}

.suspend-line {
  width: 90%;
  height: 1px;
  background-color: #cccccc;
  margin: 0 auto;
  margin-top: 10px;
}

.integral {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #cccccc;
  /* 字体颜色 */
  font-size: 14px;
  /* 字体大小 */
}

.integral div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.integral div span:first-child {
  display: block;
  font-size: 14px;
  /* 积分数字的字体大小 */
  color: #ffffff;
}

.integral div span:last-child {
  font-size: 14px;
  /* 描述文字的字体大小 */
  color: #cccccc;
}

.open-vip {
  width: 90%;
  margin: 0 auto;
}

.vip-content {
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 156, 164, 0.1),
    rgba(39, 199, 173, 0.1),
    rgba(117, 239, 156, 0.1)
  );
  background: linear-gradient(
    90deg,
    rgba(44, 156, 164, 0.1),
    rgba(39, 199, 173, 0.1),
    rgba(117, 239, 156, 0.1)
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vip-text {
  color: #fff;
  font-size: 13px;
  margin-left: 10px;
  text-align: left;
}

.vip-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.box-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.button-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;
  color: #cccccc;
  font-size: 14px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  fill: #cccccc;
}

.el-message-box__wrapper {
  width: 100% !important;
  height: 100% !important;
}

.custom-login-dialog .login-dialog {
  display: flex;
  width: 100%;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  padding: 0;
  /* 取消内边距 */
  margin: 0;
  /* 取消外边距 */
}

/* 使用 ::v-deep 选择器确保修改到 el-dialog 内部样式 */
::v-deep(.activation-code-dialog) {
  width: 320px;
  height: 200px;
  margin-top: center;
  background-image: linear-gradient(
      252.37deg,
      #1b262b 0.55%,
      #171b21,
      #191d23 90.08%
    ),
    linear-gradient(
      68.56deg,
      #1e2930 29.44%,
      #1d1d1d 59.6%,
      #262a2f 82.91%,
      #2e4141 101.21%
    ),
    url("@/assets/home/glowing.png") !important;
  border-color: #1e3139 !important;
  padding: 24px !important;

  background-size: cover;
  /* 背景图片会根据容器大小自适应 */
  background-position: center;
  /* 背景图片居中 */
}

::v-deep(.activation-code-dialog .el-dialog__title) {
  color: #fff;
}

/* 修改 el-dialog 标题部分样式 */
::v-deep(.activation-code-dialog .el-dialog__header) {
  border-bottom: none;
  /* 移除标题区域的底部边框 */
  border-radius: 12px 12px 0 0;
  /* 圆角，只对顶部生效 */
}

/* 修改激活按钮样式 */
::v-deep(.dialog-actions .el-button) {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  color: #fff;
  /* 按钮文字颜色 */
  border-radius: 8px;
  /* 按钮圆角 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* 按钮阴影 */
}

::v-deep(.dialog-actions .el-button:hover) {
  background-color: #005bb5;
  /* 按钮悬停时的背景颜色 */
}

.left-section {
  max-width: 280px;
  height: 440px;
  background-color: #00aaff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0;
}

.left-section img {
  width: 100%;
  margin-bottom: 20px;
}

.left-section h2 {
  font-size: 20px;
  margin-top: 10px;
  color: #28fb55;
}

.right-section {
  max-width: 400px;
  height: 440px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  float: right;
  margin-top: -400px;
}

.right-section h3 {
  align-self: flex-start;
  /* 靠左对齐标题 */
  color: #fff;
  font-size: 22px;
}

.right-section p {
  font-size: 14px;
  margin-top: 20px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  /* 左对齐 */
  margin-bottom: 20px;
  margin-top: 40px;
}

.tabs span {
  cursor: pointer;
  padding-bottom: 10px;
  color: #f9f9f9;
}

.tabs .active {
  color: #28fb55;
  margin-top: 20px;
  margin: 0 auto;
  font-size: 16px;
  color: #c5c7d5;
}

.input-group {
  width: 360px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input {
  flex-grow: 1;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #333;
  color: #fff;
}

.input-group button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: #5865f2;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.terms {
  font-size: 12px;
  color: #a1a1aa;
  padding-left: 10px;
}

.terms a {
  color: #28fb55;
}

.submit-btn {
  width: 90%;
  padding: 12px;
  background-color: #5865f2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 60px;
  margin-left: 10px;
}


.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
}

.faq img {
  width: 90%;
  height: 90%;
  margin-top: 10px;
  border-radius: 10px;
}
</style>